import "./dashboard.page.scss";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardBox from "../../components/dashboard-box/dashboard-box";

// import { getAllCompanies } from "../../redux/actions/company-action";

export default function Dashboard() {
	const dispatch = useDispatch();
	const companies = useSelector((state) => state.company.companies);

	useEffect(() => {
		// dispatch(getCompaniesCount());
	}, [dispatch]);

	return Object.keys(companies).length !== 0 ? (
		<div className="container">
			<span>
				<label htmlFor="company">COMPANY</label>
			</span>
			<div className="company box-design">
				{/* company */}
				<DashboardBox
					title="Companies"
					count={companies?.total_company}
					size="2"
					logo="building-o"
				/>
				<DashboardBox
					title="Active Companies"
					count={companies?.active_company}
					type="vCompanies"
					size="2"
					logo="building-o"
				/>
				<DashboardBox
					title="Verified Companies"
					count={companies?.verified_company}
					type="vCompanies"
					size="3"
					logo="building-o"
				/>
				<DashboardBox
					title="Unverified Companies"
					count={companies?.unVerified_company}
					type="uCompanies"
					size="3"
					logo="building-o"
				/>
				<DashboardBox
					title="InActive Companies"
					count={companies?.deactivated_company}
					type="uCompanies"
					size="2"
					logo="building-o"
				/>
				{/* user */}
				<DashboardBox
					title="Active Users"
					count={companies?.active_user_count}
					type="vCompanies"
					size="2"
					logo="user-o"
				/>
			</div>
			<span>
				<label htmlFor="company">BOOKING</label>
			</span>
			<div className="booking box-design">
				{/* booking */}
				<DashboardBox
					title="Total Booking"
					count={companies?.total__booking}
					type="vCompanies"
					size="2"
					logo="sticky-note"
				/>
				<DashboardBox
					title="Booking With Pax"
					count={companies?.booking_with_pax}
					type="vCompanies"
					size="2"
					logo="sticky-note"
				/>
				<DashboardBox
					title="Average Pax"
					count={companies?.average_pax}
					type="vCompanies"
					size="2"
					logo="sticky-note"
				/>
				<DashboardBox
					title="Average Rate"
					count={companies?.average_rate}
					type="vCompanies"
					size="2"
					logo="sticky-note"
				/>
				<DashboardBox
					title="Hold Booking"
					count={companies?.total_hold_booking || 0}
					type="uCompanies"
					size="2"
					logo="sticky-note"
				/>
				<DashboardBox
					title="Inquiry Booking"
					count={companies?.total_inquiry_booking || 0}
					type="uCompanies"
					size="2"
					logo="sticky-note"
				/>
				<DashboardBox
					title="Process Booking"
					count={companies?.total_process_booking || 0}
					type="vCompanies"
					size="2"
					logo="sticky-note"
				/>
				<DashboardBox
					title="Booked Booking"
					count={companies?.total_booked_booking || 0}
					type="vCompanies"
					size="2"
					logo="sticky-note"
				/>
				<DashboardBox
					title="Pending Booking"
					count={companies?.total_pending_booking || 0}
					type="uCompanies"
					size="2"
					logo="sticky-note"
				/>
				<DashboardBox
					title="Confirmed Booking"
					count={companies?.total_confirmed_booking || 0}
					type="vCompanies"
					size="2"
					logo="sticky-note"
				/>
				<DashboardBox
					title="Complete Booking"
					count={companies?.total_complete_booking || 0}
					type="vCompanies"
					size="2"
					logo="sticky-note"
				/>
				<DashboardBox
					title="Canceled Booking"
					count={companies?.total_canceled_booking || 0}
					type="uCompanies"
					size="2"
					logo="sticky-note"
				/>
			</div>
			<span>
				<label htmlFor="payment">PAYMENT</label>
			</span>
			<div className="payment box-design">
				{/* payment count*/}
				<DashboardBox
					title="Cash Payment"
					count={companies?.payment_cash_count || 0}
					type="vCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Cheque Payment"
					count={companies?.payment_cheque_count || 0}
					type="vCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Cash Refund"
					count={companies?.refund_cash_count || 0}
					type="uCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Cheque Refund"
					count={companies?.refund_cheque_count || 0}
					type="uCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Discount"
					count={companies?.discount_none_count}
					type="uCompanies"
					size="2"
					logo="money"
				/>
				{/* payment amount */}
				<DashboardBox
					title="Cash Payment Amount"
					count={`Rs.${(
						companies?.payment_cash_amount || 0
					).toLocaleString(undefined, {
						maximumFractionDigits: 2,
					})}`}
					type="vCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Cheque Payment Amount"
					count={`Rs.${(
						companies?.payment_cheque_amount || 0
					).toLocaleString(undefined, {
						maximumFractionDigits: 2,
					})}`}
					type="vCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Cash Refund Amount"
					count={`Rs.${(
						companies?.refund_cash_amount || 0
					).toLocaleString(undefined, {
						maximumFractionDigits: 2,
					})}`}
					type="uCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Cheque Refund Amount"
					count={`Rs.
					${(companies?.refund_cheque_amount || 0).toLocaleString(undefined, {
						maximumFractionDigits: 2,
					})}`}
					type="uCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Current Revenue"
					count={`Rs.${companies?.current_revenue.toLocaleString(
						undefined,
						{
							maximumFractionDigits: 2,
						}
					)}`}
					type="uCompanies"
					size="2"
					logo="money"
				/>
			</div>
			<span>
				<label htmlFor="vat">VAT</label>
			</span>
			<div className="vat box-design">
				{/* vat payment count */}
				<DashboardBox
					title="Total Vat Count"
					count={companies?.vat__count}
					type="vCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Created Vat Count"
					count={companies?.vat_created_count || 0}
					type="vCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Billed Vat Count"
					count={companies?.vat_billed_count || 0}
					type="uCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Completed Vat Count"
					count={companies?.vat_completed_count || 0}
					type="uCompanies"
					size="2"
					logo="money"
				/>
				{/* vat payment amount */}
				<DashboardBox
					title="Total Vat Amount"
					count={`Rs.${(companies?.vat_amount || 0).toLocaleString(
						undefined,
						{
							maximumFractionDigits: 2,
						}
					)}`}
					type="vCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Created Vat Amount"
					count={`Rs.${(
						companies?.vat_created_amount || 0
					).toLocaleString(undefined, {
						maximumFractionDigits: 2,
					})}`}
					type="vCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Billed Vat Amount"
					count={`Rs.${(
						companies?.vat_billed_amount || 0
					).toLocaleString(undefined, {
						maximumFractionDigits: 2,
					})}`}
					type="uCompanies"
					size="2"
					logo="money"
				/>
				<DashboardBox
					title="Completed Vat Amount"
					count={`Rs.${(
						companies?.vat_completed_amount || 0
					).toLocaleString(undefined, {
						maximumFractionDigits: 2,
					})}`}
					type="uCompanies"
					size="2"
					logo="money"
				/>
			</div>
		</div>
	) : (
		<div className="container">
			<span>
				<label htmlFor="company">DASHBOARD</label>
			</span>
		</div>
	);
}
