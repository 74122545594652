// company action
export const FETCH_COMPANY_REQUEST = "FETCH_COMPANY_REQUEST";
export const FETCH_COMPANY_FAILURE = "FETCH_COMPANY_FAILURE";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_SINGLE_COMPANY_SUCCESS = "FETCH_SINGLE_COMPANY_SUCCESS";
export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILURE = "ADD_COMPANY_SUCCESS";

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_SUCCESS";

//outside company
export const FETCH_OUTSIDE_COMPANY_REQUEST = "FETCH_OUTSIDE_COMPANY_REQUEST";
export const ADD_OUTSIDE_COMPANY_REQUEST = "ADD_OUTSIDE_COMPANY_REQUEST";
export const UPDATE_OUTSIDE_COMPANY_REQUEST = "UPDATE_OUTSIDE_COMPANY_REQUEST";
export const FETCH_OUTSIDE_COMPANY_FAILURE = "FETCH_OUTSIDE_COMPANY_FAILURE";
export const ADD_OUTSIDE_COMPANY_FAILURE = "ADD_OUTSIDE_COMPANY_FAILURE";
export const UPDATE_OUTSIDE_COMPANY_FAILURE = "UPDATE_OUTSIDE_COMPANY_FAILURE";
export const FETCH_OUTSIDE_COMPANY_SUCCESS = "FETCH_OUTSIDE_COMPANY_SUCCESS";
export const ADD_OUTSIDE_COMPANY_SUCCESS = "ADD_OUTSIDE_COMPANY_SUCCESS";
export const UPDATE_OUTSIDE_COMPANY_SUCCESS = "UPDATE_OUTSIDE_COMPANY_SUCCESS";
export const FETCH_SINGLE_OUTSIDE_COMPANY_SUCCESS =
	"FETCH_SINGLE_OUTSIDE_COMPANY_SUCCESS";

// user actions
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_ALL_USER_SUCCESS = "FETCH_ALL_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const FETCH_USER_LOGOUT = "FETCH_USER_LOGOUT";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";

export const USER_VERIFICATION_SUCCESS = "USER_VERIFICATION_SUCCESS";
export const USER_VERIFICATION_FAILURE = "USER_VERIFICATION_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const FETCH_WINLOSS_REQUEST = "FETCH_WINLOSS_REQUEST";
export const FETCH_WINLOSS_SUCCESS = "FETCH_WINLOSS_SUCCESS";
export const FETCH_WINLOSS_FAILURE = "FETCH_WINLOSS_FAILURE";
export const UPDATE_WIN_LOSS = "UPDATE_WIN_LOSS";

export const FETCH_SUPPORT_REQUEST = "FETCH_SUPPORT_REQUEST";
export const FETCH_SUPPORT_SUCCESS = "FETCH_SUPPORT_SUCCESS";
export const FETCH_SUPPORT_FAILURE = "FETCH_SUPPORT_FAILURE";
export const UPDATE_SUPPORT_REQUEST = "UPDATE_SUPPORT_REQUEST";
export const UPDATE_SUPPORT_SUCCESS = "UPDATE_SUPPORT_SUCCESS";
export const UPDATE_SUPPORT_FAILURE = "UPDATE_SUPPORT_FAILURE";

// members
export const FETCH_MEMBER_REQUEST = "FETCH_MEMBER_REQUEST";
export const FETCH_MEMBER_SUCCESS = "FETCH_MEMBER_SUCCESS";
export const FETCH_SINGLE_MEMBER_SUCCESS = "FETCH_SINGLE_MEMBER_SUCCESS";
export const FETCH_MEMBER_FAILURE = "FETCH_MEMBER_FAILURE";

export const ADD_MEMBER_REQUEST = "ADD_MEMBER_REQUEST";
export const ADD_MEMBER_SUCCESS = "ADD_MEMBER_SUCCESS";
export const ADD_MEMBER_FAILURE = "ADD_MEMBER_FAILURE";

export const UPDATE_MEMBER_REQUEST = "UPDATE_MEMBER_REQUEST";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_FAILURE = "UPDATE_MEMBER_FAILURE";

// membership
export const FETCH_MEMBERSHIP_REQUEST = "FETCH_MEMBERSHIP_REQUEST";
export const FETCH_MEMBERSHIP_SUCCESS = "FETCH_MEMBERSHIP_SUCCESS";
export const FETCH_MEMBERSHIP_FAILURE = "FETCH_MEMBERSHIP_FAILURE";

export const FETCH_COMPANY_MEMBERSHIP_REQUEST =
	"FETCH_COMPANY_MEMBERSHIP_REQUEST";
export const FETCH_COMPANY_MEMBERSHIP_SUCCESS =
	"FETCH_COMPANY_MEMBERSHIP_SUCCESS,";
export const FETCH_COMPANY_MEMBERSHIP_FAILURE =
	"FETCH_COMPANY_MEMBERSHIP_FAILURE";

export const ADD_MEMBERSHIP_REQUEST = "ADD_MEMBERSHIP_REQUEST";
export const ADD_MEMBERSHIP_SUCCESS = "ADD_MEMBERSHIP_SUCCESS";
export const ADD_MEMBERSHIP_FAILURE = "ADD_MEMBERSHIP_FAILURE";

export const UPDATE_MEMBERSHIP_REQUEST = "UPDATE_MEMBERSHIP_REQUEST";
export const UPDATE_MEMBERSHIP_SUCCESS = "UPDATE_MEMBERSHIP_SUCCESS";
export const UPDATE_MEMBERSHIP_FAILURE = "UPDATE_MEMBERSHIP_FAILURE";

export const DELETE_MEMBERSHIP_REQUEST = "DELETE_MEMBERSHIP_REQUEST";
export const DELETE_MEMBERSHIP_SUCCESS = "DELETE_MEMBERSHIP_SUCCESS";
export const DELETE_MEMBERSHIP_FAILURE = "UPDATE_MEMBERSHIP_FAILURE";

// reminder
export const FETCH_REMINDER_REQUEST = "FETCH_REMINDER_REQUEST";
export const ADD_REMINDER_REQUEST = "ADD_REMINDER_REQUEST";
export const UPDATE_REMINDER_REQUEST = "UPDATE_REMINDER_REQUEST";
export const DELETE_REMINDER_REQUEST = "DELETE_REMINDER_REQUEST";

export const FETCH_REMINDER_FAILURE = "FETCH_REMINDER_FAILURE";
export const ADD_REMINDER_FAILURE = "ADD_REMINDER_FAILURE";
export const UPDATE_REMINDER_FAILURE = "UPDATE_REMINDER_FAILURE";
export const DELETE_REMINDER_FAILURE = "DELETE_REMINDER_FAILURE";

export const FETCH_REMINDER_SUCCESS = "FETCH_REMINDER_SUCCESS";
export const SEARCH_REMINDER_SUCCESS = "SEARCH_REMINDER_SUCCESS";
export const ADD_REMINDER_SUCCESS = "ADD_REMINDER_SUCCESS";
export const UPDATE_REMINDER_SUCCESS = "UPDATE_REMINDER_SUCCESS";
export const DELETE_REMINDER_SUCCESS = "DELETE_REMINDER_SUCCESS";

// event
// request
export const FETCH_EVENT_REQUEST = "FETCH_EVENT_REQUEST";
export const ADD_EVENT_REQUEST = "ADD_EVENT_REQUEST";
export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
// failure
export const FETCH_EVENT_FAILURE = "FETCH_EVENT_FAILURE";
export const ADD_EVENT_FAILURE = "ADD_EVENT_FAILURE";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";
// success
export const FETCH_EVENT_SUCCESS = "FETCH_EVENT_SUCCESS";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const SEARCH_EVENT_SUCCESS = "SEARCH_EVENT_SUCCESS";
