import {
	FETCH_USER_REQUEST,
	FETCH_USER_SUCCESS,
	FETCH_USER_FAILURE,
	FETCH_USER_LOGOUT,
	FETCH_ALL_USER_SUCCESS,
	ADD_USER_REQUEST,
	ADD_USER_SUCCESS,
	ADD_USER_FAILURE,
	USER_VERIFICATION_SUCCESS,
	USER_VERIFICATION_FAILURE,
} from "../action-types";

const initialState = {
	user: {},
	users: [],
	userVerificationStatus: false,
	isLoading: false,
	error: "",
};

const userReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_USER_REQUEST:
		case ADD_USER_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_USER_SUCCESS:
			return {
				...state,
				user: payload,
				isLoading: false,
			};
		case USER_VERIFICATION_SUCCESS:
			return {
				...state,
				userVerificationStatus: payload,
				isLoading: false,
			};
		case FETCH_ALL_USER_SUCCESS:
		case ADD_USER_SUCCESS:
			return {
				...state,
				users: payload,
				isLoading: false,
			};
		case FETCH_USER_FAILURE:
		case ADD_USER_FAILURE:
		case USER_VERIFICATION_FAILURE:
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		case FETCH_USER_LOGOUT:
			localStorage.removeItem("evaToken");
			localStorage.removeItem("evaLoggedIn");

			return {
				isLoading: false,
				user: {},
				error: payload,
			};
		default:
			return state;
	}
};

export default userReducer;
