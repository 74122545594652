import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../components/table/Table";
import { getAllCompanies } from "../../redux/actions/company-action";

// import { getAllCompanies } from "../../redux/index";

export default function Company() {
	const dispatch = useDispatch();
	const companiesData = useSelector((state) => state.company.companies);

	useEffect(() => {
		dispatch(getAllCompanies());
	}, [dispatch]);

	const tableConstants = [
		{
			title: " ID",
			render: (rowData) => {
				return <span>{rowData.company_id}</span>;
			},
		},
		{
			title: "Company Name",
			render: (rowData) => {
				return (
					<span>
						{rowData.company_name} - {rowData.company_address},{" "}
						{rowData.company_city} - [{rowData.company_phone} ,
						{rowData.company_mobile}]
					</span>
				);
			},
		},
		{
			title: "Socials",
			render: (rowData) => {
				return <span>{rowData.company_website} </span>;
			},
		},
		{
			title: "Venues",
			render: (rowData) => {
				return <span>{rowData.company_venue_number} Venue</span>;
			},
		},
		{
			title: "Verified",
			render: (rowData) => {
				return (
					<span>{rowData.company_verified ? "True" : "False"}</span>
				);
			},
		},
		{
			title: "Active",
			render: (rowData) => {
				return <span>{rowData.company_status ? "True" : "False"}</span>;
			},
		},
		{
			title: "Member Date",
			render: (rowData) => {
				return <span>{rowData.createdAt?.split("T")[0]}</span>;
			},
		},
		// {
		// 	title: "CaterMe",
		// 	render: (rowData) => {
		// 		return (
		// 			<>
		// 				{rowData.biling ? (
		// 					<span>subscribed </span>
		// 				) : (
		// 					<span>unsubscribed </span>
		// 				)}
		// 			</>
		// 		);
		// 	},
		// },
		// {
		// 	title: "Billing",
		// 	render: (rowData) => {
		// 		return (
		// 			<>
		// 				{rowData.biling ? (
		// 					<span>subscribed </span>
		// 				) : (
		// 					<span>unsubscribed </span>
		// 				)}
		// 			</>
		// 		);
		// 	},
		// },
		// {
		// 	title: "Inventory",
		// 	render: (rowData) => {
		// 		return (
		// 			<>
		// 				{rowData.biling ? (
		// 					<span>subscribed </span>
		// 				) : (
		// 					<span>unsubscribed </span>
		// 				)}
		// 			</>
		// 		);
		// 	},
		// },
		// {
		// 	title: "Income/Expense",
		// 	render: (rowData) => {
		// 		return (
		// 			<>
		// 				{rowData.biling ? (
		// 					<span>subscribed </span>
		// 				) : (
		// 					<span>unsubscribed </span>
		// 				)}
		// 			</>
		// 		);
		// 	},
		// },
	];

	return (
		<div className="main-content">
			<Table
				cols={tableConstants}
				data={companiesData}
				isDark
				bordered
				striped
				hoverable
			/>
		</div>
	);
}
