import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import "../Company/addCompany.style.scss";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components imported
import TextInput from "../../components/text-input/text-input-error.component";

// actions
import { addReminder } from "../../redux/actions/reminder-action";

const reminderSchema = yup.object().shape({
	reminder_title: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	reminder_message: yup
		.string()
		.required("required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	reminder_date: yup.string().required("required"),
});

export default function AddReminder() {
	const history = useHistory();
	const dispatch = useDispatch();
	// displaying control state
	const [reminderFormState, setReminderFormState] = useState(true);
	const [showPreview, setShowPreview] = useState(false);

	// form submission state
	const [reminderData, setReminderState] = useState({});

	// reminder form control
	const {
		control: reminderControl,
		handleSubmit: handleReminderSubmit,
		formState: { errors: reminderErrors },
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(reminderSchema),
	});

	useEffect(() => {}, [dispatch]);

	// handling view
	const _reminderTab = () => {
		setReminderFormState(true);
		setShowPreview(false);
	};

	const _previewTab = () => {
		setReminderFormState(false);
		setShowPreview(true);
	};

	// handling form
	const _reminderFormSubmit = (data) => {
		setReminderState({ ...reminderData, ...data });
		setReminderFormState(false);
		setShowPreview(true);
	};

	const _addReminder = () => {
		const _status = dispatch(addReminder(reminderData));

		if (_status) {
			history.push({
				pathname: "/reminders",
			});
		}
	};

	// displaying form
	const reminderForm = () => {
		return (
			<form
				key={3}
				style={{ flex: 1 }}
				onSubmit={handleReminderSubmit(_reminderFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="reminder_title"
								control={reminderControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small reminderTitle"
										label="Reminder Title"
										type="text"
										field={field}
										errormessage={
											reminderErrors?.reminder_title
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="reminder_message"
								control={reminderControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small reminderMessage"
										label="Message"
										type="text"
										field={field}
										errormessage={
											reminderErrors?.reminder_message
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="reminder_date"
								control={reminderControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small reminderDate"
										type="date"
										field={field}
										errormessage={
											reminderErrors?.reminder_date
												?.message
										}
									/>
								)}
							/>
						</div>
					</div>
					<div className="user-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Next {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	// displaying data
	const reminderInfo = useMemo(() => {
		return [
			{
				label: "Reminder Title",
				value: reminderData.reminder_title,
			},
			{
				label: "Reminder Message",
				value: reminderData.reminder_message,
			},
			{
				label: "Reminder Date",
				value: reminderData.reminder_date,
			},
		];
	}, [reminderData]);

	return (
		<>
			{
				<div className="signup-main">
					<div className="signup-phase">
						<div
							className="user-form"
							onClick={() => {
								_reminderTab();
							}}
						>
							Reminder Information
						</div>

						<div
							className="preview-form"
							onClick={() => {
								_previewTab();
							}}
						>
							Preview
						</div>
					</div>

					{reminderFormState && reminderForm()}

					{showPreview && (
						<div className="preview-data-main">
							<div className="preview-user">
								<div className="section-header">
									<h2>Reminder Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _reminderTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{reminderInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>
							<div>
								<button
									className="btn btn-outline-secondary text-uppercase"
									onClick={() => _addReminder()}
								>
									Add Reminder
								</button>
							</div>
						</div>
					)}
				</div>
			}
		</>
	);
}
