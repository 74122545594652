import axios from "axios";
import {
	GET_ALL_COMPANY,
	GET_COMPANY_BY_ID,
	GET_OUTSIDE_COMPANY_BY_ID,
	ADD_COMPANY,
	UPDATE_COMPANY,
	// SEARCH_COMPANY,
	ADD_OUTSIDE_COMPANY,
	GET_ALL_OUTSIDE_COMPANY,
	SEARCH_COMPANY,
	SEARCH_OUTSIDE_COMPANY,
	UPDATE_OUTSIDE_COMPANY,
} from "../../constants/endpoints";

import {
	// requests
	FETCH_COMPANY_REQUEST,
	ADD_COMPANY_REQUEST,
	UPDATE_COMPANY_REQUEST,
	// failure
	FETCH_COMPANY_FAILURE,
	ADD_COMPANY_FAILURE,
	UPDATE_COMPANY_FAILURE,
	// success
	FETCH_COMPANY_SUCCESS,
	ADD_COMPANY_SUCCESS,
	UPDATE_COMPANY_SUCCESS,
	// FETCH_SINGLE_COMPANY_SUCCESS,
	// user reducers
	FETCH_USER_FAILURE,
	FETCH_USER_LOGOUT,
	// outside company
	ADD_OUTSIDE_COMPANY_REQUEST,
	ADD_OUTSIDE_COMPANY_SUCCESS,
	ADD_OUTSIDE_COMPANY_FAILURE,
	FETCH_OUTSIDE_COMPANY_SUCCESS,
	FETCH_OUTSIDE_COMPANY_FAILURE,
	FETCH_OUTSIDE_COMPANY_REQUEST,
	UPDATE_OUTSIDE_COMPANY_REQUEST,
	UPDATE_OUTSIDE_COMPANY_SUCCESS,
	UPDATE_OUTSIDE_COMPANY_FAILURE,
} from "../action-types.js";

import setAuthToken from "../../helpers/setAuthToken";
import {
	sessionInfo,
	isEmptyObject,
	showToastMessage,
} from "../../helpers/functions";

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch({
			type: FETCH_USER_LOGOUT,
			error: error.response.data.message,
		});
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch({
			type: FETCH_USER_FAILURE,
			error: error.response.data.message,
		});
	} else {
		showToastMessage(error.message, "E");
		await dispatch({
			type: FETCH_USER_FAILURE,
			error: error.message,
		});
	}
}

export const addCompany = (companyInfo) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: ADD_COMPANY_REQUEST,
	});

	try {
		const { data } = await axios.post(ADD_COMPANY, companyInfo);

		dispatch({
			type: ADD_COMPANY_SUCCESS,
		});

		if (data.success) {
			showToastMessage(data.message, "S");
		} else {
			showToastMessage(data.message, "E");
		}

		return data.success;
	} catch (err) {
		dispatch({
			type: ADD_COMPANY_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};
export const addOutsideCompany = (companyInfo) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: ADD_OUTSIDE_COMPANY_REQUEST,
	});

	try {
		const { data } = await axios.post(ADD_OUTSIDE_COMPANY, companyInfo);

		dispatch({
			type: ADD_OUTSIDE_COMPANY_SUCCESS,
		});

		if (data.success) {
			showToastMessage(data.message, "S");
		} else {
			showToastMessage(data.message, "E");
		}

		return data.success;
	} catch (err) {
		dispatch({
			type: ADD_OUTSIDE_COMPANY_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};

export const getAllCompanies = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_COMPANY_REQUEST,
	});

	try {
		const response = await axios.get(GET_ALL_COMPANY);

		dispatch({
			type: FETCH_COMPANY_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_COMPANY_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};
export const getAllOutsideCompanies = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_OUTSIDE_COMPANY_REQUEST,
	});

	try {
		const response = await axios.get(GET_ALL_OUTSIDE_COMPANY);

		dispatch({
			type: FETCH_OUTSIDE_COMPANY_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_OUTSIDE_COMPANY_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};

export const getCompanyById = (company_info_id) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_COMPANY_REQUEST,
	});

	try {
		const response = await axios.get(
			GET_COMPANY_BY_ID + `?company_info_id=${company_info_id}`
		);

		dispatch({
			type: FETCH_COMPANY_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_COMPANY_FAILURE,
			error: err.message,
		});
		_handleError(err, dispatch);
	}
};
export const getOutsideCompanyById =
	(outside_company_id) => async (dispatch) => {
		const sessionData = await sessionInfo();

		if (localStorage.evaLoggedIn) {
			setAuthToken(sessionData.token);
		}

		dispatch({
			type: FETCH_OUTSIDE_COMPANY_REQUEST,
		});

		try {
			const response = await axios.get(
				GET_OUTSIDE_COMPANY_BY_ID +
					`?outside_company_id=${outside_company_id}`
			);

			dispatch({
				type: FETCH_OUTSIDE_COMPANY_SUCCESS,
				payload: response.data.result,
			});
		} catch (err) {
			dispatch({
				type: FETCH_OUTSIDE_COMPANY_FAILURE,
				error: err.message,
			});
			_handleError(err, dispatch);
		}
	};

export const searchCompany = (searchData) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_COMPANY_REQUEST,
	});

	try {
		const response = await axios.get(
			SEARCH_COMPANY +
				`?company_id=${searchData.company_id}&company_name=${searchData.company_name}&company_phone=${searchData.company_phone}&company_mobile=${searchData.company_mobile}&company_status=${searchData.company_status}`
		);

		dispatch({
			type: FETCH_COMPANY_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_COMPANY_FAILURE,
			error: err.message,
		});
		_handleError(err, dispatch);
	}
};
export const searchOutsideCompany = (searchData) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_OUTSIDE_COMPANY_REQUEST,
	});

	try {
		const response = await axios.get(
			SEARCH_OUTSIDE_COMPANY +
				`?company_type=${searchData.company_type}&company_name=${searchData.company_name}&company_phone=${searchData.company_phone}&company_mobile=${searchData.company_mobile}&company_status=${searchData.company_status}`
		);

		dispatch({
			type: FETCH_OUTSIDE_COMPANY_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_OUTSIDE_COMPANY_FAILURE,
			error: err.message,
		});
		_handleError(err, dispatch);
	}
};

export const updateCompany = (companyInfo) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: UPDATE_COMPANY_REQUEST,
	});

	try {
		await axios.put(UPDATE_COMPANY, companyInfo);

		dispatch({
			type: UPDATE_COMPANY_SUCCESS,
		});
	} catch (err) {
		dispatch({
			type: UPDATE_COMPANY_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};
export const updateOutsideCompany = (companyInfo) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: UPDATE_OUTSIDE_COMPANY_REQUEST,
	});

	try {
		await axios.put(UPDATE_OUTSIDE_COMPANY, companyInfo);

		dispatch({
			type: UPDATE_OUTSIDE_COMPANY_SUCCESS,
		});
	} catch (err) {
		dispatch({
			type: UPDATE_OUTSIDE_COMPANY_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};
