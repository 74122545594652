import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import "../Company/addCompany.style.scss";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components imported
import TextInput from "../../components/text-input/text-input-error.component";
import SelectBox from "../../components/select-box/select-box.input";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// actions
import { addEvent } from "../../redux/actions/event-action";
import {} from "@mui/material";
import { getMemberList } from "../../redux/actions/member-action";

const eventSchema = yup.object().shape({
	event_title: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	event_date: yup.string().required("required"),
	// event_message: yup
	// 	.string()
	// 	.required("required")
	// 	.matches(/^([^0-9]*)$/, "No numbers allowed"),
	members: yup.string().required("required"),
	// .array()
	// .default([])
	// .min([1, "choose at least more than 1"])
	// .required("choose at least more then 1"),
});

export default function AddEvent() {
	const history = useHistory();
	const dispatch = useDispatch();
	// displaying control state
	const [event_message, setMessage] = useState("");
	const [eventFormState, setEventFormState] = useState(true);
	const [showPreview, setShowPreview] = useState(false);

	// form submission state
	const [eventData, setEventState] = useState({});
	const memberList = useSelector((state) => state.member.members);
	const membersList = memberList.map((mem) => ({
		id: mem.member_info_id,
		value: mem.first_name + " " + mem.last_name,
	}));

	// event form control
	const {
		control: eventControl,
		handleSubmit: handleEventSubmit,
		formState: { errors: eventErrors },
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(eventSchema),
	});

	useEffect(() => {
		dispatch(getMemberList());
	}, [dispatch]);

	// handling view
	const _eventTab = () => {
		setEventFormState(true);
		setShowPreview(false);
	};

	const _previewTab = () => {
		setEventFormState(false);
		setShowPreview(true);
	};

	// handling form
	const _eventFormSubmit = (data) => {
		setEventState({ ...eventData, ...data, event_message });
		setEventFormState(false);
		setShowPreview(true);
	};

	const _addEvent = () => {
		const _status = dispatch(addEvent(eventData));

		if (_status) {
			history.push({
				pathname: "/events",
			});
		}
	};

	// displaying form
	const eventForm = () => {
		return (
			<form
				key={3}
				style={{ flex: 1 }}
				onSubmit={handleEventSubmit(_eventFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="event_title"
								control={eventControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small eventTitle"
										label="Event Title"
										type="text"
										field={field}
										errormessage={
											eventErrors?.event_title?.message
										}
									/>
								)}
							/>

							<Controller
								name="event_date"
								control={eventControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small eventDate"
										type="date"
										field={field}
										errormessage={
											eventErrors?.event_date?.message
										}
									/>
								)}
							/>
						</div>

						<div className="form-input-container">
							<Controller
								name="members"
								control={eventControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small members"
										className="search"
										label="Members"
										field={field}
										size="small"
										options={membersList}
										required
										errormessage={
											eventErrors?.members?.message
												?.members?.message
										}
									/>
								)}
							/>
							<CKEditor
								name="event_message"
								editor={ClassicEditor}
								data="<p>Dear Eva Members,&nbsp;</p><p>Coming this 1st June 2024, we are going to have our monthly meeting. We request all the members to have their presence and support the association we have lot to achieve and purpose of meeting are as follows;&nbsp;</p><ul><li>setting goals for new fiscal year&nbsp;</li><li>using software in order to digitize everything&nbsp;</li><li>collection data using the software&nbsp;</li></ul><p>Thank You,&nbsp;</p><p>Best Regards&nbsp;</p><p><strong>EVA NEPAL</strong></p></p>"
								onChange={(event, editor) => {
									const data = editor.getData();
									setMessage(data);
								}}
								config={{
									removePlugins: [
										"EasyImage",
										"ImageToolbar",
										"ImageUpload",
										"MediaEmbed",
										"Table",
									],
								}}
							/>
						</div>
					</div>
					<div className="user-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Next {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	// displaying data
	const eventInfo = useMemo(() => {
		return [
			{
				label: "Event Title",
				value: eventData.event_title,
			},
			{
				label: "Event Date",
				value: eventData.event_date,
			},
			{
				label: "Members",
				value: eventData.members,
			},
			{
				label: "Event Message",
				value: event_message,
			},
		];
	}, [eventData, event_message]);

	return (
		<>
			{
				<div className="signup-main">
					<div className="signup-phase">
						<div
							className="user-form"
							onClick={() => {
								_eventTab();
							}}
						>
							Event Information
						</div>

						<div
							className="preview-form"
							onClick={() => {
								_previewTab();
							}}
						>
							Preview
						</div>
					</div>

					{eventFormState && eventForm()}

					{showPreview && (
						<div className="preview-data-main">
							<div className="preview-user">
								<div className="section-header">
									<h2>Event Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _eventTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{eventInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>
							<div>
								<button
									className="btn btn-outline-secondary text-uppercase"
									onClick={() => _addEvent()}
								>
									Add Event
								</button>
							</div>
						</div>
					)}
				</div>
			}
		</>
	);
}
