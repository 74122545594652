import React from "react";
import "./dashboard-box.scss";

const DashboardBox = ({ title, count, type, size, logo }) => {
	return (
		// <div className={`col-md-${size} col-lg-${size}`}>
		<div className={`card-container`}>
			<div className="card">
				<div className="card-header">
					<div className="cardTitle">
						<h5 className="card-category">{title}</h5>
					</div>
					<div className="clearfix"></div>
				</div>
				<div className="card-body">
					<h2>
						{count}
						<span className="pull-right">
							<i
								className={`fa fa-${logo} ${type}`}
								aria-hidden="true"
							></i>
						</span>
					</h2>
				</div>
			</div>
		</div>
	);
};

export default DashboardBox;
