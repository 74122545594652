import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./select-box.style.scss";
export default function SelectBox(props) {
	return (
		<div>
			<FormControl variant="outlined" className={props.className}>
				<InputLabel id="demo-simple-select-standard-label" size="small">
					{props.label}
				</InputLabel>
				<Select
					// labelId="demo-simple-select-standard-label"
					// id="demo-simple-select-standard"
					{...props}
					{...props.field}
				>
					<MenuItem value="" disabled>
						<em>None</em>
					</MenuItem>
					{props.options.map((val) => (
						<MenuItem value={val.id} key={val.id}>
							{val.value}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
}
