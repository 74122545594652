import React from "react";
import "./text-input-error.style.scss";

import { TextField } from "@mui/material";

const TextInput = (props) => {
	return (
		<div className="input-container">
			<TextField
				variant="outlined"
				{...props}
				{...props.field}
				// endAdornment={props.endadornment}
				error={props.errormessage ? true : false}
			/>
			<span className="error-message">{props.errormessage}</span>
		</div>
	);
};
export default TextInput;
