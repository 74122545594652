import React from "react";
// import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import "../Company/addCompany.style.scss";

import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components imported
import TextInput from "../../components/text-input/text-input-error.component";

const whatsappSchema = yup.object().shape({
	message: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	mobile_number: yup
		.number()
		.typeError("Must be a number")
		.required("required"),
});

export default function SendWhatsapp() {
	// const history = useHistory();
	// const dispatch = useDispatch();

	// whatsapp form control
	const {
		control: whatsappControl,
		handleSubmit: handleWhatsappSubmit,
		formState: { errors: whatsappErrors },
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(whatsappSchema),
	});

	const _sendWhatsapp = async (data) => {
		console.log(data);

		try {
			await axios.post("http://localhost:3004/whatsapp/sendMessage", {
				to: data.mobile_number,
				message: data.message,
			});
			alert("WhatsApp message sent successfully!");
		} catch (error) {
			console.error("Error sending WhatsApp message:", error);
			alert("Failed to send WhatsApp message");
		}

		// dispatch(sendWhatsapp(whatsappData));
		// history.push({
		// 	pathname: "/whatsapp",
		// });
	};

	// displaying form
	const whatsappForm = () => {
		return (
			<form
				key={3}
				style={{ flex: 1 }}
				onSubmit={handleWhatsappSubmit(_sendWhatsapp)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="message"
								control={whatsappControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small message"
										label="Message"
										type="text"
										field={field}
										errormessage={
											whatsappErrors?.message?.message
										}
									/>
								)}
							/>
							<Controller
								name="mobile_number"
								control={whatsappControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small mobile_number"
										label="Mobile Number"
										type="number"
										field={field}
										errormessage={
											whatsappErrors?.mobile_number
												?.message
										}
									/>
								)}
							/>
						</div>
					</div>
					<div className="user-action-btn">
						<button className="btn btn-outline-secondary">
							Submit
						</button>
					</div>
				</div>
			</form>
		);
	};

	return (
		<>
			{
				<div className="signup-main">
					<div className="signup-phase">
						<div className="user-form">Whatsapp Message</div>
					</div>

					{whatsappForm()}
				</div>
			}
		</>
	);
}
