import React from "react";
import "./Table.scss";

const Table = ({ cols, data, bordered, hoverable, striped, isDark }) => {
	return (
		<div className="table-responsive">
			<table
				className={`table ${
					bordered ? "table-bordered" : "table-borderless"
				} ${hoverable && "table-hover"} ${striped && "table-striped"} ${
					isDark && "table-dark"
				}}`}
			>
				<thead>
					<tr>
						<th>#</th>
						{cols?.map((headerItem, index) => (
							<th key={index}>{headerItem.title}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data?.map((item, index) => (
						<tr key={index}>
							<td>{index + 1}</td>
							{cols?.map((col, key) => (
								<td key={key}>{col.render(item)}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default Table;
