import {
	// request
	FETCH_REMINDER_REQUEST,
	ADD_REMINDER_REQUEST,
	UPDATE_REMINDER_REQUEST,
	DELETE_REMINDER_REQUEST,
	// failure
	FETCH_REMINDER_FAILURE,
	ADD_REMINDER_FAILURE,
	UPDATE_REMINDER_FAILURE,
	DELETE_REMINDER_FAILURE,
	// success
	FETCH_REMINDER_SUCCESS,
	ADD_REMINDER_SUCCESS,
	UPDATE_REMINDER_SUCCESS,
	DELETE_REMINDER_SUCCESS,
	SEARCH_REMINDER_SUCCESS,
} from "../action-types";

const initialState = {
	reminders: [],
	navReminders: [],
	reminder: {},
	unseenReminder: 0,
	loading: false,
	error: "",
};

const reminderReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_REMINDER_REQUEST:
		case ADD_REMINDER_REQUEST:
		case UPDATE_REMINDER_REQUEST:
		case DELETE_REMINDER_REQUEST:
			return {
				...state,
				loading: true,
			};

		case FETCH_REMINDER_SUCCESS:
		case ADD_REMINDER_SUCCESS:
		case UPDATE_REMINDER_SUCCESS:
		case DELETE_REMINDER_SUCCESS:
			return {
				...state,
				reminders: payload.rows,
				navReminders: payload.navReminders,
				unseenReminder: payload.count,
				loading: false,
			};

		case SEARCH_REMINDER_SUCCESS:
			return {
				...state,
				reminders: payload,
				loading: false,
			};

		case FETCH_REMINDER_FAILURE:
		case ADD_REMINDER_FAILURE:
		case UPDATE_REMINDER_FAILURE:
		case DELETE_REMINDER_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
};

export default reminderReducer;
