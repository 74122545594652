import { NEW_API_URL } from "./url";

// LOGIN URL
export const LOGIN_USER = `${NEW_API_URL}/user/login`;
export const ADD_USER = `${NEW_API_URL}/user/add`;
export const USER_VERIFICATION = `${NEW_API_URL}/user/verification`;
export const GET_ALL_USER = `${NEW_API_URL}/user`;
export const FORGOT_PASSWORD = `${NEW_API_URL}/user/forgot/password`;
export const RESET_PASSWORD = `${NEW_API_URL}/user/reset/password`;
export const LOGOUT_USER = `${NEW_API_URL}/user/logout`;

// COMPANY URL
export const GET_ALL_COMPANY = `${NEW_API_URL}/company/all`;
export const GET_COMPANY_BY_ID = `${NEW_API_URL}/company/id`;
export const SEARCH_COMPANY = `${NEW_API_URL}/company/search`;
export const ADD_COMPANY = `${NEW_API_URL}/company/add`;
export const UPDATE_COMPANY = `${NEW_API_URL}/company/update`;

// OUTSIDE COMPANY URL
export const GET_ALL_OUTSIDE_COMPANY = `${NEW_API_URL}/company/outside/all`;
export const GET_OUTSIDE_COMPANY_BY_ID = `${NEW_API_URL}/company/outside/id`;
export const SEARCH_OUTSIDE_COMPANY = `${NEW_API_URL}/company/outside/search`;
export const ADD_OUTSIDE_COMPANY = `${NEW_API_URL}/company/outside/add`;
export const UPDATE_OUTSIDE_COMPANY = `${NEW_API_URL}/company/outside/update`;

// EVA MEMBER URL
export const GET_MEMBER_LIST = `${NEW_API_URL}/member/all`;
export const GET_MEMBER_BY_ID = `${NEW_API_URL}/member/id`;
export const SEARCH_MEMBER = `${NEW_API_URL}/member/search`;
export const ADD_MEMBER = `${NEW_API_URL}/member/add`;
export const ADD_COMPANY_MEMBER = `${NEW_API_URL}/member/company/add`;
export const UPDATE_MEMBER = `${NEW_API_URL}/member/update`;

// MEMBERSHIP URL
export const GET_MEMBERSHIP_LIST = `${NEW_API_URL}/membership/all`;
export const GET_MEMBERSHIP_BY_COMPANY = `${NEW_API_URL}/membership/company`;
export const ADD_NEW_MEMBERSHIP = `${NEW_API_URL}/membership/add`;
export const UPDATE_MEMBERSHIP = `${NEW_API_URL}/membership/update`;
export const DELETE_MEMBERSHIP = `${NEW_API_URL}/membership/remove`;

// REMINDER URL
export const GET_ALL_REMINDER = `${NEW_API_URL}/reminder`;
export const SEARCH_REMINDER = `${NEW_API_URL}/reminder/search`;
export const ADD_REMINDER = `${NEW_API_URL}/reminder`;
export const UPDATE_REMINDER = `${NEW_API_URL}/reminder/update`;
export const DELETE_REMINDER = `${NEW_API_URL}/reminder/delete`;

// EVENT URL
export const GET_ALL_EVENT = `${NEW_API_URL}/event`;
export const SEARCH_EVENT = `${NEW_API_URL}/event/search`;
export const ADD_EVENT = `${NEW_API_URL}/event`;
export const UPDATE_EVENT = `${NEW_API_URL}/event/update`;
export const DELETE_EVENT = `${NEW_API_URL}/event/delete`;
