import "./App.scss";
// setting up the redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//pages
import Login from "./pages/Login/login.page.jsx";
import Dashboard from "./pages/Dashboard/dashboard.page";
import Company from "./pages/Company/company.page.jsx";
import AddCompany from "./pages/Company/addCompany.page.jsx";
import User from "./pages/User/user.page.jsx";
import AddUser from "./pages/User/addUser.page";
import UserVerification from "./pages/User/user-verification.page";
import Member from "./pages/Member/member.page.jsx";
import AddMember from "./pages/Member/addMember.page.jsx";
import Membership from "./pages/Membership/membership.page";

import ProtectedRoute from "./routes/ProtectedRoute";
import history from "./helpers/history";

import SendWhatsapp from "./pages/Whatsapp/sendWhatsapp.page.jsx";
import OutsideCompany from "./pages/Outside Company/outsideCompany.page.jsx";
import AddOutsideCompany from "./pages/Outside Company/addOutsideCompany.page.jsx";
import AddReminder from "./pages/Reminder/addReminder.page.jsx";
import Reminder from "./pages/Reminder/reminder.page.jsx";

import AddEvent from "./pages/Event/addEvent.page.jsx";
import Event from "./pages/Event/event.page.jsx";

function App() {
	return (
		<Provider store={store}>
			<div className="App">
				<Router history={history}>
					<Switch>
						<Route exact path="/" component={Login} />
						<ProtectedRoute
							exact
							path="/dashboard"
							component={Dashboard}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/company"
							component={Company}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/add/company"
							component={AddCompany}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/outsideCompany"
							component={OutsideCompany}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/add/outsideCompany"
							component={AddOutsideCompany}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/add/user"
							component={AddUser}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/users"
							component={User}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/add/member"
							component={AddMember}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/members"
							component={Member}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/membership"
							component={Membership}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/whatsapp"
							component={SendWhatsapp}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/add/reminder"
							component={AddReminder}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/reminders"
							component={Reminder}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/add/event"
							component={AddEvent}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/events"
							component={Event}
							navigation={true}
						/>

						<Route
							exact
							path="/user/verification/:token/:email"
							component={UserVerification}
							navigation={true}
						/>
						{/* <ProtectedRoute
							exact
							path="/support"
							component={SupportIssuesPage}
							navigation={true}
						/> */}
						{/* <ProtectedRoute
							exact
							path="/caterPackage"
							component={CaterPackage}
							navigation={true}
						/> */}
					</Switch>
				</Router>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</Provider>
	);
}

export default App;
