import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./reminder.style.scss";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";

import {
	getAllReminders,
	updateReminder,
} from "../../redux/actions/reminder-action";

export default function Reminder() {
	const dispatch = useDispatch();
	const reminders = useSelector((state) => state.reminder.reminders);

	useEffect(() => {
		dispatch(getAllReminders());
	}, [dispatch]);

	const _updateReminder = (reminder) => {
		dispatch(updateReminder({ ...reminder, reminder_status: "seen" }));
	};

	return (
		<div className="user-container">
			<div className="user-container-inner">
				{reminders?.map((reminder, index) => (
					<div
						key={index}
						className="user-item-container"
						onClick={() => _updateReminder(reminder)}
					>
						<div className="item-img-container">
							<SelfImprovementIcon className="user-icon" />
						</div>
						<div className="item-details-container">
							<div>
								<h4 className="item-mainText">
									{reminder.reminder_title}
									{reminder.reminder_status === "unseen" ? (
										<OnlinePredictionIcon className="user-online active" />
									) : (
										<WifiOffIcon className="user-online offline" />
									)}
								</h4>
								<div className="details-divider" />
							</div>
							<div style={{ flex: 1 }}>
								<div className="item-info">
									<div className="info-icon c">
										<LocationOnIcon className="menu-icon" />
									</div>
									<div className="info-details">
										<p className="subText">Message</p>
										<p className="mainText">
											{reminder.reminder_message},
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
