import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./event.style.scss";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";

import { getAllEvents, updateEvent } from "../../redux/actions/event-action";

export default function Event() {
	const dispatch = useDispatch();
	const events = useSelector((state) => state.event.events);

	useEffect(() => {
		dispatch(getAllEvents());
	}, [dispatch]);

	const _updateEvent = (event) => {
		dispatch(updateEvent({ ...event, event_status: "active" }));
	};

	return (
		<div className="user-container">
			<div className="user-container-inner">
				{events?.map((event, index) => (
					<div
						key={index}
						className="user-item-container"
						onClick={() => _updateEvent(event)}
					>
						<div className="item-img-container">
							<SelfImprovementIcon className="user-icon" />
						</div>
						<div className="item-details-container">
							<div>
								<h4 className="item-mainText">
									{event.event_title}
									{event.event_status === "active" ? (
										<OnlinePredictionIcon className="user-online active" />
									) : (
										<WifiOffIcon className="user-online offline" />
									)}
								</h4>
								<div className="details-divider" />
							</div>
							<div style={{ flex: 1 }}>
								<div className="item-info">
									<div className="info-icon c">
										<LocationOnIcon className="menu-icon" />
									</div>
									<div className="info-details">
										<p className="subText">Message</p>
										<p className="mainText">
											{event.event_message},
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
