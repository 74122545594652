import axios from "axios";
import setAuthToken from "../../helpers/setAuthToken";

import {
	GET_ALL_USER,
	LOGIN_USER,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	LOGOUT_USER,
	ADD_USER,
	USER_VERIFICATION,
} from "../../constants/endpoints";
import {
	sessionInfo,
	isEmptyObject,
	showToastMessage,
} from "../../helpers/functions";

import {
	// user request actions
	FETCH_USER_REQUEST,
	FETCH_USER_SUCCESS,
	FETCH_ALL_USER_SUCCESS,
	FETCH_USER_FAILURE,
	FETCH_USER_LOGOUT,
	// ADDING USER
	ADD_USER_REQUEST,
	ADD_USER_SUCCESS,
	ADD_USER_FAILURE,
	// user verification
	USER_VERIFICATION_SUCCESS,
	USER_VERIFICATION_FAILURE,
	// password reset actions
	FORGOT_PASSWORD_REQUEST,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAILURE,
	RESET_PASSWORD_REQUEST,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAILURE,
} from "../action-types.js";

export const fetchLogoutRequest = () => {
	return {
		type: FETCH_USER_LOGOUT,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch({
			type: FETCH_USER_LOGOUT,
			error: error.response.data.message,
		});
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch({
			type: FETCH_USER_FAILURE,
			error: error.response.data.message,
		});
	} else {
		showToastMessage(error.message, "E");
		await dispatch({
			type: FETCH_USER_FAILURE,
			error: error.message,
		});
	}
}

export const getAllUsers = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_USER_REQUEST,
	});

	try {
		const response = await axios.get(GET_ALL_USER);

		dispatch({
			type: FETCH_ALL_USER_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_USER_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};

export const loginUser = (data) => async (dispatch) => {
	dispatch({
		type: FETCH_USER_REQUEST,
	});

	axios
		.post(LOGIN_USER, data)
		.then((response) => {
			if (response.data.success) {
				showToastMessage(response.data.message, "S");

				localStorage.setItem("evaToken", response.data.token);
				localStorage.setItem("evaLoggedIn", true);

				dispatch({
					type: FETCH_USER_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: FETCH_USER_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const addUser = (data) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: ADD_USER_REQUEST,
	});

	axios
		.post(ADD_USER, data)
		.then((response) => {
			if (response.data.success) {
				showToastMessage(response.data.message, "S");

				dispatch({
					type: ADD_USER_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: ADD_USER_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const userVerification = (data) => async (dispatch) => {
	axios
		.post(
			USER_VERIFICATION +
				`?user_email=${data.email}&verificationToken=${data.token}`,
			data
		)
		.then((response) => {
			if (response.data.success) {
				showToastMessage(response.data.message, "S");

				localStorage.setItem("evaToken", response.data.token);
				localStorage.setItem("evaLoggedIn", true);

				dispatch({
					type: USER_VERIFICATION_SUCCESS,
					payload: response.data.success,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: USER_VERIFICATION_FAILURE,
					payload: response.data.success,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const forgotPassword = (data) => async (dispatch) => {
	dispatch({
		type: FORGOT_PASSWORD_REQUEST,
	});
	axios
		.post(FORGOT_PASSWORD, data)
		.then((response) => {
			if (response.data.success) {
				showToastMessage(response.data.message, "S");

				dispatch({
					type: FORGOT_PASSWORD_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");

				dispatch({
					type: FORGOT_PASSWORD_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const resetPassword = (data) => async (dispatch) => {
	dispatch({
		type: RESET_PASSWORD_REQUEST,
	});
	axios
		.put(RESET_PASSWORD, data)
		.then((response) => {
			if (response.data.success) {
				showToastMessage(response.data.message, "S");

				dispatch({
					type: RESET_PASSWORD_SUCCESS,
				});
			} else {
				showToastMessage(response.data.message, "E");

				dispatch({
					type: RESET_PASSWORD_FAILURE,
					error: response.data.message,
				});

				return response.data.success;
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const logoutUser = () => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		return axios
			.delete(LOGOUT_USER, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchLogoutRequest());
					showToastMessage(response.data.message, "S");
				} else {
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
