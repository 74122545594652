import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import "./addOutsideCompany.style.scss";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components imported
import {
	genderOption,
	cityOption,
	companyTypeOption,
} from "../../constants/variables";
import SelectBox from "../../components/select-box/select-box.input";
import TextInput from "../../components/text-input/text-input-error.component";

// actions
import { addOutsideCompany } from "../../redux/actions/company-action";

const companySchema = yup.object().shape({
	company_name: yup.string().required("company name required"),
	company_type: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("type of company is required"),
	company_email: yup
		.string()
		.required("company email is required")
		.email("Please enter a valid email"),
	company_mobile: yup
		.number()
		.required("company mobile number is required")
		.typeError("company mobile number is required"),
	company_phone: yup
		.number()
		.required("company phone number is required")
		.typeError("company phone number is required"),
	company_website: yup.string(),
	company_facebook: yup.string(),
	company_address: yup.string().required("company address is required"),
	company_city: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("city of company is required"),
});

const contactInfoSchema = yup.object().shape({
	first_name: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	last_name: yup
		.string()
		.required("required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	primary_contact: yup
		.number()
		.typeError("Must be a number")
		.required("required"),
	member_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
	member_dob: yup.string().required("required"),
	member_gender: yup.string().required("required"),
	address: yup.string().required("required"),
	city: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
});

export default function AddOutsideCompany() {
	const history = useHistory();
	const dispatch = useDispatch();
	// displaying control state
	const [companyFormState, setCompanyFormState] = useState(true);
	const [memberFormState, setMemberFormState] = useState(false);
	const [showPreview, setShowPreview] = useState(false);

	// form submission state

	const [companyInfoState, setCompanyInfoState] = useState({
		company_city: "kathmandu",
		company_address: "teku",
		company_facebook: "",
		company_website: "",
		company_phone: 142324641,
		company_mobile: 9853213211,
		company_email: "nibjarsolution@yopmail.com",
		company_type: "IT",
		company_name: "NIbjar Solutions",
		city: "kathmandu",
		address: "dhumbarahi",
		member_gender: "male",
		member_dob: "1944-01-01",
		member_email: "rajbin@yopmail.com",
		primary_contact: 155243627,
		last_name: "Singh",
		first_name: "Rajbin",
	});

	// company form control
	const {
		control: companyControl,
		handleSubmit: handleCompanySubmit,
		formState: { errors: companyErrors },
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(companySchema),
	});

	// member form control
	const {
		control: memberControl,
		handleSubmit: handleMemberSubmit,
		formState: { errors: memberErrors },
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(contactInfoSchema),
	});

	useEffect(() => {}, [dispatch]);

	// handling view
	const _companyTab = () => {
		setCompanyFormState(true);
		setMemberFormState(false);
		setShowPreview(false);
	};
	const _memberTab = () => {
		setCompanyFormState(false);
		setMemberFormState(true);
		setShowPreview(false);
	};
	const _previewTab = () => {
		setCompanyFormState(false);
		setMemberFormState(false);
		setShowPreview(true);
	};

	// handling form
	const _companyFormSubmit = (data) => {
		setCompanyInfoState({ ...companyInfoState, ...data });
		setCompanyFormState(false);
		setMemberFormState(true);
	};

	const _userFormSubmit = (data) => {
		setCompanyInfoState({ ...companyInfoState, ...data });
		setMemberFormState(false);
		setShowPreview(true);
	};

	const _addNewCompany = async () => {
		const _status = await dispatch(addOutsideCompany(companyInfoState));

		if (_status) {
			history.push({
				pathname: "/outsideCompany",
			});
		}
	};

	// displaying form
	const companyForm = () => {
		return (
			<form
				key={1}
				style={{ flex: 1 }}
				onSubmit={handleCompanySubmit(_companyFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="company_name"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyName"
										label="Company Name"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_type"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small company_type"
										className="search"
										label="Type"
										field={field}
										size="small"
										options={companyTypeOption}
										required
										errormessage={
											companyErrors?.company_type?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_email"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyEmail"
										label="Company Email"
										type="email"
										field={field}
										errormessage={
											companyErrors?.company_email
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_mobile"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyMobile"
										label="Company Mobile"
										type="number"
										field={field}
										errormessage={
											companyErrors?.company_mobile
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_phone"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyPhone"
										label="Company Phone"
										type="number"
										field={field}
										errormessage={
											companyErrors?.company_phone
												?.message
										}
									/>
								)}
							/>
						</div>

						<div className="form-input-container">
							<Controller
								name="company_website"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										id="outlined-size-small companyWebsite"
										label="Company Website"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_website
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_facebook"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										id="outlined-size-small companyFacebook"
										label="Company Facebook"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_facebook
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_address"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyAddress"
										label="Company Address"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_address
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_city"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small companyCity"
										className="search"
										label="City"
										field={field}
										size="small"
										options={cityOption}
										required
										errormessage={
											companyErrors?.company_city?.message
										}
									/>
								)}
							/>
						</div>
					</div>

					<div className="company-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Next {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	const userForm = () => {
		return (
			<form
				key={3}
				style={{ flex: 1 }}
				onSubmit={handleMemberSubmit(_userFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="first_name"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small firstName"
										label="First Name"
										type="text"
										field={field}
										errormessage={
											memberErrors?.first_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="last_name"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small lastName"
										label="Last Name"
										type="text"
										field={field}
										errormessage={
											memberErrors?.last_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="primary_contact"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userMobile"
										label="Primary Contact"
										type="number"
										field={field}
										errormessage={
											memberErrors?.primary_contact
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="member_email"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userEmail"
										label="Member Email"
										type="email"
										field={field}
										errormessage={
											memberErrors?.member_email?.message
										}
									/>
								)}
							/>
						</div>
						<div className="form-input-container">
							<Controller
								name="member_gender"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small member_gender"
										className="search"
										label="Gender"
										field={field}
										size="small"
										options={genderOption}
										required
										errormessage={
											memberErrors?.member_gender?.message
												?.member_gender?.message
										}
									/>
								)}
							/>
							<Controller
								name="member_dob"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userDOB"
										// label="Date of Birth"
										type="date"
										field={field}
										errormessage={
											memberErrors?.member_dob?.message
										}
									/>
								)}
							/>
							<Controller
								name="address"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userAddress"
										label="Address"
										type="text"
										field={field}
										errormessage={
											memberErrors?.address?.message
										}
									/>
								)}
							/>
							<Controller
								name="city"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small userCity"
										className="search"
										label="City"
										field={field}
										size="small"
										options={cityOption}
										required
										errormessage={
											memberErrors?.city?.message
										}
									/>
								)}
							/>
						</div>
					</div>

					<div className="user-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="button"
							onClick={() => {
								setCompanyFormState(true);
								setMemberFormState(false);
							}}
						>
							{"< "}Back
						</button>
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Next {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	// displaying data
	const companyInfo = useMemo(() => {
		return [
			{
				label: "Company Name",
				value: companyInfoState.company_name,
			},
			{
				label: "Company Type",
				value: companyInfoState.company_type,
			},
			{
				label: "Email",
				value: companyInfoState.company_email,
			},
			{
				label: "Mobile",
				value: companyInfoState.company_mobile,
			},
			{
				label: "Phone",
				value: companyInfoState.company_phone,
			},
			{
				label: "Website",
				value: companyInfoState.company_website || "-",
			},
			{
				label: "Facebook",
				value: companyInfoState.company_facebook || "-",
			},
			{
				label: "Address",
				value: companyInfoState.company_address,
			},
			{
				label: "City",
				value: companyInfoState.company_city,
			},
		];
	}, [companyInfoState]);

	const userInfo = useMemo(() => {
		return [
			{
				label: "First Name",
				value: companyInfoState.first_name,
			},
			{
				label: "Last Name",
				value: companyInfoState.last_name,
			},
			{
				label: "Gender",
				value: companyInfoState.member_gender,
			},
			{
				label: "Date of Birth",
				value: companyInfoState.member_dob || "-",
			},
			{
				label: "Mobile",
				value: companyInfoState.primary_contact,
			},
			{
				label: "Email",
				value: companyInfoState.member_email,
			},
			{
				label: "Address",
				value: companyInfoState.address,
			},
			{
				label: "City",
				value: companyInfoState.city,
			},
		];
	}, [companyInfoState]);

	return (
		<>
			{
				<div className="signup-main">
					<div className="signup-phase">
						<div
							className="company-form"
							onClick={() => {
								_companyTab();
							}}
						>
							Company Information
						</div>
						<div
							className="user-form"
							onClick={() => {
								_memberTab();
							}}
						>
							Member Information
						</div>
						<div
							className="preview-form"
							onClick={() => {
								_previewTab();
							}}
						>
							Preview
						</div>
					</div>

					{companyFormState && companyForm()}
					{memberFormState && userForm()}

					{showPreview && (
						<div className="preview-data-main">
							<div className="preview-company">
								<div className="section-header">
									<h2>Company Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _companyTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{companyInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>

							<div className="preview-user">
								<div className="section-header">
									<h2>Member Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _memberTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{userInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>
							<div>
								<button
									className="btn btn-outline-secondary text-uppercase"
									onClick={() => _addNewCompany()}
								>
									Add Member
								</button>
							</div>
						</div>
					)}
				</div>
			}
		</>
	);
}
