import {
	// requests
	FETCH_COMPANY_REQUEST,
	FETCH_OUTSIDE_COMPANY_REQUEST,
	ADD_COMPANY_REQUEST,
	ADD_OUTSIDE_COMPANY_REQUEST,
	UPDATE_COMPANY_REQUEST,
	UPDATE_OUTSIDE_COMPANY_REQUEST,
	// failure
	FETCH_COMPANY_FAILURE,
	FETCH_OUTSIDE_COMPANY_FAILURE,
	ADD_COMPANY_FAILURE,
	ADD_OUTSIDE_COMPANY_FAILURE,
	UPDATE_COMPANY_FAILURE,
	UPDATE_OUTSIDE_COMPANY_FAILURE,
	// success
	FETCH_COMPANY_SUCCESS,
	FETCH_OUTSIDE_COMPANY_SUCCESS,
	ADD_COMPANY_SUCCESS,
	ADD_OUTSIDE_COMPANY_SUCCESS,
	UPDATE_COMPANY_SUCCESS,
	UPDATE_OUTSIDE_COMPANY_SUCCESS,
	FETCH_SINGLE_COMPANY_SUCCESS,
	FETCH_SINGLE_OUTSIDE_COMPANY_SUCCESS,
} from "../action-types";

const initialState = {
	companies: [],
	outsideCompanies: [],
	company: {},
	outsideCompany: {},
	loading: false,
	error: "",
};

const companyReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_COMPANY_REQUEST:
		case ADD_COMPANY_REQUEST:
		case UPDATE_COMPANY_REQUEST:
		case FETCH_OUTSIDE_COMPANY_REQUEST:
		case ADD_OUTSIDE_COMPANY_REQUEST:
		case UPDATE_OUTSIDE_COMPANY_REQUEST:
			return {
				...state,
				loading: true,
			};

		case FETCH_COMPANY_SUCCESS:
		case UPDATE_COMPANY_SUCCESS:
		case ADD_COMPANY_SUCCESS:
			return {
				...state,
				companies: payload,
				loading: false,
			};

		case FETCH_OUTSIDE_COMPANY_SUCCESS:
		case ADD_OUTSIDE_COMPANY_SUCCESS:
		case UPDATE_OUTSIDE_COMPANY_SUCCESS:
			return {
				...state,
				outsideCompanies: payload,
				loading: false,
			};

		case FETCH_SINGLE_COMPANY_SUCCESS:
			return {
				...state,
				company: payload,
				loading: false,
			};

		case FETCH_SINGLE_OUTSIDE_COMPANY_SUCCESS:
			return {
				...state,
				outsideCompany: payload,
				loading: false,
			};

		case FETCH_COMPANY_FAILURE:
		case ADD_COMPANY_FAILURE:
		case UPDATE_COMPANY_FAILURE:
		case FETCH_OUTSIDE_COMPANY_FAILURE:
		case ADD_OUTSIDE_COMPANY_FAILURE:
		case UPDATE_OUTSIDE_COMPANY_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
};

export default companyReducer;
