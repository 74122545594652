import axios from "axios";
import {
	GET_ALL_REMINDER,
	SEARCH_REMINDER,
	ADD_REMINDER,
	UPDATE_REMINDER,
	DELETE_REMINDER,
} from "../../constants/endpoints";

import {
	// request
	FETCH_REMINDER_REQUEST,
	ADD_REMINDER_REQUEST,
	UPDATE_REMINDER_REQUEST,
	DELETE_REMINDER_REQUEST,

	// failure
	FETCH_REMINDER_FAILURE,
	ADD_REMINDER_FAILURE,
	UPDATE_REMINDER_FAILURE,
	DELETE_REMINDER_FAILURE,
	FETCH_USER_FAILURE,

	// success
	FETCH_REMINDER_SUCCESS,
	SEARCH_REMINDER_SUCCESS,
	ADD_REMINDER_SUCCESS,
	UPDATE_REMINDER_SUCCESS,
	DELETE_REMINDER_SUCCESS,
	FETCH_USER_LOGOUT,
} from "../action-types.js";

import setAuthToken from "../../helpers/setAuthToken";
import {
	sessionInfo,
	isEmptyObject,
	showToastMessage,
} from "../../helpers/functions";

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch({
			type: FETCH_USER_LOGOUT,
			error: error.response.data.message,
		});
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch({
			type: FETCH_USER_FAILURE,
			error: error.response.data.message,
		});
	} else {
		showToastMessage(error.message, "E");
		await dispatch({
			type: FETCH_USER_FAILURE,
			error: error.message,
		});
	}
}
export const addReminder = (reminder) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: ADD_REMINDER_REQUEST,
	});

	try {
		const { data } = await axios.post(ADD_REMINDER, reminder);

		dispatch({
			type: ADD_REMINDER_SUCCESS,
			payload: data.result,
		});

		if (data.success) {
			showToastMessage(data.message, "S");
		} else {
			showToastMessage(data.message, "E");
		}

		return data.success;
	} catch (err) {
		dispatch({
			type: ADD_REMINDER_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};

export const getAllReminders = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_REMINDER_REQUEST,
	});

	try {
		const response = await axios.get(GET_ALL_REMINDER);

		dispatch({
			type: FETCH_REMINDER_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_REMINDER_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};

export const searchReminder = (searchData) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_REMINDER_REQUEST,
	});

	try {
		const response = await axios.get(
			SEARCH_REMINDER + `?searchText=${searchData.searchText}`
		);

		dispatch({
			type: SEARCH_REMINDER_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_REMINDER_FAILURE,
			error: err.message,
		});
		_handleError(err, dispatch);
	}
};

export const updateReminder = (reminderInfo) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: UPDATE_REMINDER_REQUEST,
	});

	try {
		const { data } = await axios.put(UPDATE_REMINDER, reminderInfo);

		dispatch({
			type: UPDATE_REMINDER_SUCCESS,
			payload: data.result,
		});

		if (data.success) {
			showToastMessage(data.message, "S");
		} else {
			showToastMessage(data.message, "E");
		}

		return data.success;
	} catch (err) {
		dispatch({
			type: UPDATE_REMINDER_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};
export const deleteReminder = (reminder_id) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: DELETE_REMINDER_REQUEST,
	});

	try {
		const { data } = await axios.delete(
			DELETE_REMINDER + `?reminder_id=${reminder_id}`
		);

		dispatch({
			type: DELETE_REMINDER_SUCCESS,
			payload: data.result,
		});

		if (data.success) {
			showToastMessage(data.message, "S");
		} else {
			showToastMessage(data.message, "E");
		}

		return data.success;
	} catch (err) {
		dispatch({
			type: DELETE_REMINDER_FAILURE,
			error: err.message,
		});

		_handleError(err, dispatch);
	}
};
