import {
	// request
	FETCH_EVENT_REQUEST,
	ADD_EVENT_REQUEST,
	UPDATE_EVENT_REQUEST,
	DELETE_EVENT_REQUEST,
	// failure
	FETCH_EVENT_FAILURE,
	ADD_EVENT_FAILURE,
	UPDATE_EVENT_FAILURE,
	DELETE_EVENT_FAILURE,
	// success
	FETCH_EVENT_SUCCESS,
	ADD_EVENT_SUCCESS,
	UPDATE_EVENT_SUCCESS,
	DELETE_EVENT_SUCCESS,
	SEARCH_EVENT_SUCCESS,
} from "../action-types";

const initialState = {
	events: [],
	eventCount: 0,
	navEvents: [],
	loading: false,
	error: "",
};

const eventReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_EVENT_REQUEST:
		case ADD_EVENT_REQUEST:
		case UPDATE_EVENT_REQUEST:
		case DELETE_EVENT_REQUEST:
			return {
				...state,
				loading: true,
			};

		case FETCH_EVENT_SUCCESS:
		case ADD_EVENT_SUCCESS:
		case UPDATE_EVENT_SUCCESS:
		case DELETE_EVENT_SUCCESS:
			return {
				...state,
				events: payload.rows,
				eventCount: payload.count,
				navEvents: payload.navEvents,
				loading: false,
			};

		case SEARCH_EVENT_SUCCESS:
			return {
				...state,
				events: payload,
				loading: false,
			};

		case FETCH_EVENT_FAILURE:
		case ADD_EVENT_FAILURE:
		case UPDATE_EVENT_FAILURE:
		case DELETE_EVENT_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
};

export default eventReducer;
