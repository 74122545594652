import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import "../Company/addCompany.style.scss";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components imported
import {
	genderOption,
	cityOption,
	rolesOption,
} from "../../constants/variables";
import SelectBox from "../../components/select-box/select-box.input";
import TextInput from "../../components/text-input/text-input-error.component";

// actions
import { addUser } from "../../redux/actions/login-action";

const userSchema = yup.object().shape({
	first_name: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	last_name: yup
		.string()
		.required("required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	primary_contact: yup
		.number()
		.typeError("Must be a number")
		.required("required"),
	user_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
	password: yup
		.string()
		.required("required")
		.min(8, "Min characters should be 8"),
	confirm_password: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required("required"),
	user_dob: yup.string().required("required"),
	user_role: yup.string().required("required"),
	user_gender: yup.string().required("required"),
	address: yup.string().required("required"),
	city: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
});

export default function AddUser() {
	const history = useHistory();
	const dispatch = useDispatch();
	// displaying control state
	const [userFormState, setUserFormState] = useState(true);
	const [showPreview, setShowPreview] = useState(false);

	// form submission state
	const [userData, setUserState] = useState({});

	// user form control
	const {
		control: userControl,
		handleSubmit: handleUserSubmit,
		formState: { errors: userErrors },
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(userSchema),
	});

	useEffect(() => {}, [dispatch]);

	// handling view
	const _userTab = () => {
		setUserFormState(true);
		setShowPreview(false);
	};

	const _previewTab = () => {
		setUserFormState(false);
		setShowPreview(true);
	};

	// handling form
	const _userFormSubmit = (data) => {
		setUserState({ ...userData, ...data });
		setUserFormState(false);
		setShowPreview(true);
	};

	const _addUser = () => {
		dispatch(addUser(userData));
		history.push({
			pathname: "/users",
		});
	};

	// displaying form
	const userForm = () => {
		return (
			<form
				key={3}
				style={{ flex: 1 }}
				onSubmit={handleUserSubmit(_userFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="first_name"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small firstName"
										label="First Name"
										type="text"
										field={field}
										errormessage={
											userErrors?.first_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="last_name"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small lastName"
										label="Last Name"
										type="text"
										field={field}
										errormessage={
											userErrors?.last_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="primary_contact"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userMobile"
										label="Primary Contact"
										type="number"
										field={field}
										errormessage={
											userErrors?.primary_contact?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_email"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userEmail"
										label="User Email"
										type="email"
										field={field}
										errormessage={
											userErrors?.user_email?.message
										}
									/>
								)}
							/>
							<Controller
								name="password"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userPassword"
										label="Password"
										type="password"
										field={field}
										errormessage={
											userErrors?.password?.message
										}
									/>
								)}
							/>
							<Controller
								name="confirm_password"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small confirmPassword"
										label="Confirm Password"
										type="password"
										field={field}
										errormessage={
											userErrors?.confirm_password
												?.message
										}
									/>
								)}
							/>
						</div>
						<div className="form-input-container">
							<Controller
								name="user_dob"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userDOB"
										// label="Date of Birth"
										type="date"
										field={field}
										errormessage={
											userErrors?.user_dob?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_role"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small package_type"
										className="search"
										label="User Role"
										field={field}
										size="small"
										options={rolesOption}
										required
										errormessage={
											userErrors?.user_role?.message
												?.user_role?.message
										}
									/>
								)}
							/>
							<Controller
								name="user_gender"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small package_type"
										className="search"
										label="Gender"
										field={field}
										size="small"
										options={genderOption}
										required
										errormessage={
											userErrors?.user_gender?.message
												?.user_gender?.message
										}
									/>
								)}
							/>
							<Controller
								name="address"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userAddress"
										label="Address"
										type="text"
										field={field}
										errormessage={
											userErrors?.address?.message
										}
									/>
								)}
							/>
							<Controller
								name="city"
								control={userControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small userCity"
										className="search"
										label="City"
										field={field}
										size="small"
										options={cityOption}
										required
										errormessage={userErrors?.city?.message}
									/>
								)}
							/>
						</div>
					</div>
					<div className="user-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Next {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	// displaying data
	const userInfo = useMemo(() => {
		return [
			{
				label: "First Name",
				value: userData.first_name,
			},
			{
				label: "Last Name",
				value: userData.last_name,
			},
			{
				label: "Role",
				value: userData.user_role,
			},
			{
				label: "Gender",
				value: userData.user_gender,
			},
			{
				label: "Date of Birth",
				value: userData.user_dob || "-",
			},
			{
				label: "Primary Contact",
				value: userData.primary_contact,
			},
			{
				label: "Email",
				value: userData.user_email,
			},
			{
				label: "Address",
				value: userData.address,
			},
			{
				label: "City",
				value: userData.city,
			},
		];
	}, [userData]);

	return (
		<>
			{
				<div className="signup-main">
					<div className="signup-phase">
						<div
							className="user-form"
							onClick={() => {
								_userTab();
							}}
						>
							User Information
						</div>

						<div
							className="preview-form"
							onClick={() => {
								_previewTab();
							}}
						>
							Preview
						</div>
					</div>

					{userFormState && userForm()}

					{showPreview && (
						<div className="preview-data-main">
							<div className="preview-user">
								<div className="section-header">
									<h2>User Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _userTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{userInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>
							<div>
								<button
									className="btn btn-outline-secondary text-uppercase"
									onClick={() => _addUser()}
								>
									Add User
								</button>
							</div>
						</div>
					)}
				</div>
			}
		</>
	);
}
