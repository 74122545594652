import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import "./addCompany.style.scss";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components imported
import { genderOption, cityOption } from "../../constants/variables";
import SelectBox from "../../components/select-box/select-box.input";
import TextInput from "../../components/text-input/text-input-error.component";

// actions
import { addCompany } from "../../redux/actions/company-action";

const companySchema = yup.object().shape({
	company_name: yup.string().required("company name required"),
	company_venue_number: yup
		.number("Not Selected")
		.positive("must not be negative value")
		.lessThan(4, "maximum 3 venue are allowed")
		.required("venue declaration is required")
		.typeError("venue declaration is required"),
	company_email: yup
		.string()
		.required("company email is required")
		.email("Please enter a valid email"),
	company_mobile: yup
		.number()
		.required("company mobile number is required")
		.typeError("company mobile number is required"),
	company_phone: yup
		.number()
		.required("company phone number is required")
		.typeError("company phone number is required"),
	company_website: yup.string(),
	company_facebook: yup.string(),
	company_address: yup.string().required("company address is required"),
	company_city: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("city of company is required"),
});

const memberSchema = yup.object().shape({
	first_name: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	last_name: yup
		.string()
		.required("required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	primary_contact: yup
		.number()
		.typeError("Must be a number")
		.required("required"),
	member_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
	member_dob: yup.string().required("required"),
	member_gender: yup.string().required("required"),
	address: yup.string().required("required"),
	city: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
});

const membershipSchema = yup.object().shape({
	membership_type: yup.string().required("membership type is required"),
	membership_duration: yup
		.number()
		.typeError("membership duration number is required")
		.required("membership duration number is required"),
	membership_amount: yup
		.number()
		.typeError("membership amount number is required")
		.required("membership amount number is required"),
	renewed_date: yup.string().required("required"),
	expiration_date: yup.string().required("required"),
});

export default function AddCompany() {
	const history = useHistory();
	const dispatch = useDispatch();
	// displaying control state
	const [companyFormState, setCompanyFormState] = useState(true);
	const [memberFormState, setMemberFormState] = useState(false);
	const [membershipFormState, setMembershipFormState] = useState(false);
	const [showPreview, setShowPreview] = useState(false);

	// form submission state

	const [companyInfoState, setCompanyInfoState] = useState({
		// company_city: "dharan",
		// company_address: "dharan teku",
		// company_facebook: "",
		// company_website: "",
		// company_phone: 143232521,
		// company_mobile: 14423223,
		// company_email: "test1235@yopmail.com",
		// company_venue_number: 1,
		// company_name: "Test Add Company",
		// city: "janakpur",
		// address: "janakpur chowk",
		// member_gender: "male",
		// member_dob: "2023-10-19",
		// member_email: "testusr1123@yopmail.com",
		// primary_contact: 14134312,
		// last_name: "Test",
		// first_name: "Usr",
		// expiration_date: "2025-06-01",
		// renewed_date: "2024-06-01",
		// membership_amount: 5000,
		// membership_duration: 12,
		// membership_type: "yearly",
	});

	// company form control
	const {
		control: companyControl,
		handleSubmit: handleCompanySubmit,
		formState: { errors: companyErrors },
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(companySchema),
	});

	// member form control
	const {
		control: memberControl,
		handleSubmit: handleMemberSubmit,
		formState: { errors: memberErrors },
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(memberSchema),
	});

	// membership form control
	const {
		control: evaMembershipControl,
		handleSubmit: handleMembershipsubmit,
		formState: { errors: evaMembershipErrors },
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(membershipSchema),
	});

	useEffect(() => {}, [dispatch]);

	// handling view
	const _companyTab = () => {
		setCompanyFormState(true);
		setMemberFormState(false);
		setMembershipFormState(false);
		setShowPreview(false);
	};
	const _memberTab = () => {
		setCompanyFormState(false);
		setMemberFormState(true);
		setMembershipFormState(false);
		setShowPreview(false);
	};
	const _evaMembershipTab = () => {
		setCompanyFormState(false);
		setMemberFormState(false);
		setMembershipFormState(true);
		setShowPreview(false);
	};
	const _previewTab = () => {
		setCompanyFormState(false);
		setMemberFormState(false);
		setMembershipFormState(false);
		setShowPreview(true);
	};

	// handling form
	const _companyFormSubmit = (data) => {
		setCompanyInfoState({ ...companyInfoState, ...data });
		setCompanyFormState(false);
		setMemberFormState(true);
	};

	const _userFormSubmit = (data) => {
		setCompanyInfoState({ ...companyInfoState, ...data });
		setMemberFormState(false);
		setMembershipFormState(true);
	};

	const _evaMembershipFormSubmit = (data) => {
		setCompanyInfoState({ ...companyInfoState, ...data });
		setMembershipFormState(false);
		setShowPreview(true);
	};

	const _addNewCompany = async () => {
		const _status = await dispatch(addCompany(companyInfoState));

		if (_status) {
			history.push({
				pathname: "/company",
			});
		}
	};

	// displaying form
	const companyForm = () => {
		return (
			<form
				key={1}
				style={{ flex: 1 }}
				onSubmit={handleCompanySubmit(_companyFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="company_name"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyName"
										label="Company Name"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_venue_number"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small venueNumber"
										label="No. of Venue"
										type="number"
										field={field}
										errormessage={
											companyErrors?.company_venue_number
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_email"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyEmail"
										label="Company Email"
										type="email"
										field={field}
										errormessage={
											companyErrors?.company_email
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_mobile"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyMobile"
										label="Company Mobile"
										type="number"
										field={field}
										errormessage={
											companyErrors?.company_mobile
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_phone"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyPhone"
										label="Company Phone"
										type="number"
										field={field}
										errormessage={
											companyErrors?.company_phone
												?.message
										}
									/>
								)}
							/>
						</div>

						<div className="form-input-container">
							<Controller
								name="company_website"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										id="outlined-size-small companyWebsite"
										label="Company Website"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_website
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_facebook"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										id="outlined-size-small companyFacebook"
										label="Company Facebook"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_facebook
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_address"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small companyAddress"
										label="Company Address"
										type="text"
										field={field}
										errormessage={
											companyErrors?.company_address
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="company_city"
								control={companyControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small companyCity"
										className="search"
										label="City"
										field={field}
										size="small"
										options={cityOption}
										required
										errormessage={
											companyErrors?.company_city?.message
										}
									/>
								)}
							/>
						</div>
					</div>

					<div className="company-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Next {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	const userForm = () => {
		return (
			<form
				key={3}
				style={{ flex: 1 }}
				onSubmit={handleMemberSubmit(_userFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="first_name"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small firstName"
										label="First Name"
										type="text"
										field={field}
										errormessage={
											memberErrors?.first_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="last_name"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small lastName"
										label="Last Name"
										type="text"
										field={field}
										errormessage={
											memberErrors?.last_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="primary_contact"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userMobile"
										label="Primary Contact"
										type="number"
										field={field}
										errormessage={
											memberErrors?.primary_contact
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="member_email"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userEmail"
										label="Member Email"
										type="email"
										field={field}
										errormessage={
											memberErrors?.member_email?.message
										}
									/>
								)}
							/>
						</div>
						<div className="form-input-container">
							<Controller
								name="member_gender"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small member_gender"
										className="search"
										label="Gender"
										field={field}
										size="small"
										options={genderOption}
										required
										errormessage={
											memberErrors?.member_gender?.message
												?.member_gender?.message
										}
									/>
								)}
							/>
							<Controller
								name="member_dob"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userDOB"
										// label="Date of Birth"
										type="date"
										field={field}
										errormessage={
											memberErrors?.member_dob?.message
										}
									/>
								)}
							/>
							<Controller
								name="address"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userAddress"
										label="Address"
										type="text"
										field={field}
										errormessage={
											memberErrors?.address?.message
										}
									/>
								)}
							/>
							<Controller
								name="city"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small userCity"
										className="search"
										label="City"
										field={field}
										size="small"
										options={cityOption}
										required
										errormessage={
											memberErrors?.city?.message
										}
									/>
								)}
							/>
						</div>
					</div>

					<div className="user-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="button"
							onClick={() => {
								membershipFormState(true);
								setMemberFormState(false);
							}}
						>
							{"< "}Back
						</button>
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Next {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	const evaMembershipForm = () => {
		return (
			<form
				key={2}
				style={{ flex: 1 }}
				onSubmit={handleMembershipsubmit(_evaMembershipFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="membership_type"
								control={evaMembershipControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small membership_type"
										label="Membership Type"
										type="text"
										field={field}
										errormessage={
											evaMembershipErrors?.membership_type
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="membership_duration"
								control={evaMembershipControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small membershipDuration"
										label="Membership Duration"
										type="number"
										field={field}
										errormessage={
											evaMembershipErrors
												?.membership_duration?.message
										}
									/>
								)}
							/>
							<Controller
								name="membership_amount"
								control={evaMembershipControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small membershipAmount"
										label="Membership Amount"
										type="number"
										field={field}
										errormessage={
											evaMembershipErrors
												?.membership_amount?.message
										}
									/>
								)}
							/>
							<Controller
								name="renewed_date"
								control={evaMembershipControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small renewedDate"
										label="Renewed Date"
										type="date"
										field={field}
										errormessage={
											evaMembershipErrors?.renewed_date
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="expiration_date"
								control={evaMembershipControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small expirationDate"
										label="Expiration Date"
										type="date"
										field={field}
										errormessage={
											evaMembershipErrors?.expiration_date
												?.message
										}
									/>
								)}
							/>
						</div>
					</div>
					<div className="user-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="button"
							onClick={() => {
								setMemberFormState(true);
								setMembershipFormState(false);
							}}
						>
							{"< "}Back
						</button>
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Preview {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	// displaying data
	const companyInfo = useMemo(() => {
		return [
			{
				label: "Company Name",
				value: companyInfoState.company_name,
			},
			{
				label: "No. of Venue",
				value: companyInfoState.company_venue_number + " venue/s",
			},
			{
				label: "Email",
				value: companyInfoState.company_email,
			},
			{
				label: "Mobile",
				value: companyInfoState.company_mobile,
			},
			{
				label: "Phone",
				value: companyInfoState.company_phone,
			},
			{
				label: "Website",
				value: companyInfoState.company_website || "-",
			},
			{
				label: "Facebook",
				value: companyInfoState.company_facebook || "-",
			},
			{
				label: "Address",
				value: companyInfoState.company_address,
			},
			{
				label: "City",
				value: companyInfoState.company_city,
			},
		];
	}, [companyInfoState]);

	const userInfo = useMemo(() => {
		return [
			{
				label: "First Name",
				value: companyInfoState.first_name,
			},
			{
				label: "Last Name",
				value: companyInfoState.last_name,
			},
			{
				label: "Gender",
				value: companyInfoState.member_gender,
			},
			{
				label: "Date of Birth",
				value: companyInfoState.member_dob || "-",
			},
			{
				label: "Mobile",
				value: companyInfoState.primary_contact,
			},
			{
				label: "Email",
				value: companyInfoState.member_email,
			},
			{
				label: "Address",
				value: companyInfoState.address,
			},
			{
				label: "City",
				value: companyInfoState.city,
			},
		];
	}, [companyInfoState]);

	const evaMembershipInfo = useMemo(() => {
		return [
			{
				label: "Membership Type",
				value: companyInfoState.membership_type,
			},
			{
				label: "Membership Duration",
				value:
					(companyInfoState.membership_duration || "-") + " Month/s",
			},
			{
				label: "Renewed Date",
				value: companyInfoState.renewed_date,
			},
			{
				label: "Expiration Date",
				value: companyInfoState.expiration_date,
			},
			{
				label: "Membership Amount",
				value: "Rs." + (companyInfoState.membership_amount || "-"),
			},
		];
	}, [companyInfoState]);

	return (
		<>
			{
				<div className="signup-main">
					<div className="signup-phase">
						<div
							className="company-form"
							onClick={() => {
								_companyTab();
							}}
						>
							Company Information
						</div>
						<div
							className="user-form"
							onClick={() => {
								_memberTab();
							}}
						>
							Member Information
						</div>
						<div
							className="user-form"
							onClick={() => {
								_evaMembershipTab();
							}}
						>
							Membership
						</div>
						<div
							className="preview-form"
							onClick={() => {
								_previewTab();
							}}
						>
							Preview
						</div>
					</div>

					{companyFormState && companyForm()}
					{memberFormState && userForm()}
					{membershipFormState && evaMembershipForm()}

					{showPreview && (
						<div className="preview-data-main">
							<div className="preview-company">
								<div className="section-header">
									<h2>Company Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _companyTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{companyInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>

							<div className="preview-user">
								<div className="section-header">
									<h2>Member Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _memberTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{userInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>

							<div className="preview-user">
								<div className="section-header">
									<h2>Subscription Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _evaMembershipTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{evaMembershipInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>

							<div>
								<button
									className="btn btn-outline-secondary text-uppercase"
									onClick={() => _addNewCompany()}
								>
									Add Member
								</button>
							</div>
						</div>
					)}
				</div>
			}
		</>
	);
}
