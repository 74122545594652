import {
	// FETCH
	FETCH_MEMBERSHIP_REQUEST,
	FETCH_COMPANY_MEMBERSHIP_REQUEST,
	ADD_MEMBERSHIP_REQUEST,
	UPDATE_MEMBERSHIP_REQUEST,
	DELETE_MEMBERSHIP_REQUEST,
	// FAILURE
	FETCH_MEMBERSHIP_FAILURE,
	ADD_MEMBERSHIP_FAILURE,
	UPDATE_MEMBERSHIP_FAILURE,
	DELETE_MEMBERSHIP_FAILURE,
	FETCH_COMPANY_MEMBERSHIP_FAILURE,
	// SUCCESS
	FETCH_MEMBERSHIP_SUCCESS,
	ADD_MEMBERSHIP_SUCCESS,
	UPDATE_MEMBERSHIP_SUCCESS,
	DELETE_MEMBERSHIP_SUCCESS,
	FETCH_COMPANY_MEMBERSHIP_SUCCESS,
} from "../action-types";

const initialState = {
	memberships: [],
	companyMemberships: [],
	isLoading: false,
	error: "",
};

const caterPackageReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_MEMBERSHIP_REQUEST:
		case FETCH_COMPANY_MEMBERSHIP_REQUEST:
		case ADD_MEMBERSHIP_REQUEST:
		case UPDATE_MEMBERSHIP_REQUEST:
		case DELETE_MEMBERSHIP_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case FETCH_COMPANY_MEMBERSHIP_SUCCESS:
			return {
				...state,
				isLoading: false,
				companyMemberships: payload,
			};

		case FETCH_MEMBERSHIP_SUCCESS:
		case ADD_MEMBERSHIP_SUCCESS:
		case UPDATE_MEMBERSHIP_SUCCESS:
		case DELETE_MEMBERSHIP_SUCCESS:
			return {
				...state,
				memberships: payload,
				isLoading: false,
			};

		case FETCH_MEMBERSHIP_FAILURE:
		case ADD_MEMBERSHIP_FAILURE:
		case UPDATE_MEMBERSHIP_FAILURE:
		case DELETE_MEMBERSHIP_FAILURE:
		case FETCH_COMPANY_MEMBERSHIP_FAILURE:
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default caterPackageReducer;
