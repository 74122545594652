import { combineReducers } from "redux";
import loginReducer from "./reducers/login-reducer";
import companyReducer from "./reducers/company-reducer";
import memberReducer from "./reducers/member-reducer";
import membershipReducer from "./reducers/membership-reducer";
import reminderReducer from "./reducers/reminder-reducer";
import eventReducer from "./reducers/event-reducer";

const rootReducer = combineReducers({
	company: companyReducer,
	login: loginReducer,
	member: memberReducer,
	membership: membershipReducer,
	reminder: reminderReducer,
	event: eventReducer,
});

export default rootReducer;
