import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../components/table/Table";
import { getAllOutsideCompanies } from "../../redux/actions/company-action";

// import { getAllCompanies } from "../../redux/index";

export default function OutsideCompany() {
	const dispatch = useDispatch();
	const outsideCompaniesData = useSelector(
		(state) => state.company.outsideCompanies
	);

	console.log(outsideCompaniesData);
	useEffect(() => {
		dispatch(getAllOutsideCompanies());
	}, [dispatch]);

	const tableConstants = [
		{
			title: "Company Name",
			render: (rowData) => {
				return (
					<span>
						{rowData.company_name} - {rowData.company_address},{" "}
						{rowData.company_city} - [{rowData.company_phone} ,
						{rowData.company_mobile}]
					</span>
				);
			},
		},
		{
			title: "Company Type",
			render: (rowData) => {
				return <span>{rowData.company_type}</span>;
			},
		},
		{
			title: "Socials",
			render: (rowData) => {
				return <span>{rowData.company_website} </span>;
			},
		},
		{
			title: "Active",
			render: (rowData) => {
				return <span>{rowData.company_status ? "True" : "False"}</span>;
			},
		},
		{
			title: "Member Date",
			render: (rowData) => {
				return <span>{rowData.createdAt?.split("T")[0]}</span>;
			},
		},
	];

	return (
		<div className="main-content">
			<Table
				cols={tableConstants}
				data={outsideCompaniesData}
				isDark
				bordered
				striped
				hoverable
			/>
		</div>
	);
}
