import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import "./membership.style.scss";
import WatchIcon from "@mui/icons-material/Watch";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";

import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components imported
import Table from "../../components/table/Table";
import SelectBox from "../../components/select-box/select-box.input";
import TextInput from "../../components/text-input/text-input-error.component";

import { getAllCompanies } from "../../redux/actions/company-action";
import {
	getMembershipList,
	addMembership,
	updateMembership,
} from "../../redux/actions/membership-action";

const membershipSchema = yup.object().shape({
	company_info_id: yup.string().required("company is required"),
	membership_type: yup.string().required("membership type is required"),
	membership_duration: yup
		.number()
		.typeError("membership duration number is required")
		.required("membership duration number is required"),
	membership_amount: yup
		.number()
		.typeError("membership amount number is required")
		.required("membership amount number is required"),
	renewed_date: yup.string().required("required"),
	expiration_date: yup.string().required("required"),
});

export default function Membership() {
	const dispatch = useDispatch();
	const currentDate = moment().format("YYYY-MM-DDD");
	const [actionButton, setActionButtonState] = useState("add");
	const [oldMembership, setOldMembershipState] = useState({});
	const companies = useSelector((state) => state.company.companies);
	const memberships = useSelector((state) => state.membership.memberships);

	// for select box
	const companyInfos = companies.map((company) => {
		return {
			id: company.company_info_id,
			value: company.company_name,
		};
	});

	// membership form control
	const {
		control: evaMembershipControl,
		handleSubmit: handleMembershipsubmit,
		formState: { errors: evaMembershipErrors },
		reset: evaMembershipReset,
		// watch: membershipTypeWatch,
		setValue: setMembershipValue,
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(membershipSchema),
	});

	useEffect(() => {
		dispatch(getMembershipList());
		dispatch(getAllCompanies());
	}, [dispatch]);

	// handling form
	const _evaMembershipFormSubmit = (data) => {
		const addData = {
			company_info_id: data.company_info_id,
			membership_type: data.membership_type.toLowerCase(),
			membership_duration: data.membership_duration,
			renewed_date: data.renewed_date,
			expiration_date: data.expiration_date,
			membership_amount: data.membership_amount,
		};

		switch (actionButton) {
			case "add":
				dispatch(
					addMembership({
						...addData,
						membership_status: "active",
					})
				);
				break;
			case "edit":
				dispatch(
					updateMembership({
						...data,
						membership_status: data.membership_status,
					})
				);
				break;
			case "renew":
				dispatch(
					updateMembership({
						...oldMembership,
						membership_id: data.membership_id,
						company_info_id: data.company_info_id,
						membership_status: "expired",
					})
				);

				dispatch(
					addMembership({
						...addData,
						membership_status: data.membership_status,
					})
				);

				break;
			default:
				break;
		}

		evaMembershipReset({
			company_info_id: "",
			membership_type: "",
			membership_duration: "",
			membership_amount: "",
			renewed_date: "",
			expiration_date: "",
		});
		setMembershipValue("membership_amount", "");
		setMembershipValue("membership_duration", "");
		setActionButtonState("add");
	};

	const _editEvaMembership = (rowData, action) => {
		evaMembershipReset(rowData);
		setMembershipValue("membership_amount", rowData.membership_amount);
		setMembershipValue("membership_duration", rowData.membership_duration);

		if (action === "renew") {
			setOldMembershipState(rowData);
			setMembershipValue("renewed_date", rowData.expiration_date);
			setMembershipValue(
				"expiration_date",
				moment(rowData.expiration_date)
					.add(rowData.membership_duration, "M")
					.format("YYYY-MM-DD")
			);
		}

		setActionButtonState(action);
	};

	// const membershipWatchFields = membershipTypeWatch("membership_type");
	// const _packageChoose = () => {
	// 	if (membershipWatchFields) {
	// 		const packageValue = "";
	// 		setMembershipValue(
	// 			"membership_amount",
	// 			packageValue.package_amount
	// 		);
	// 		setMembershipValue(
	// 			"membership_duration",
	// 			packageValue.package_duration
	// 		);
	// 	}
	// };

	// displaying package listed
	const membershipListTable = [
		{
			title: (
				<>
					<CardMembershipIcon className="menu-icon" /> Company
				</>
			),
			render: (rowData) => {
				return <span>{rowData.company_info.company_name}</span>;
			},
		},
		{
			title: (
				<>
					<WatchIcon className="menu-icon" /> Membership Type
				</>
			),
			render: (rowData) => {
				return <span>{rowData.membership_type}</span>;
			},
		},
		{
			title: (
				<>
					<PriceCheckIcon className="menu-icon" /> Membership Duration
				</>
			),
			render: (rowData) => {
				return <span>{rowData.membership_duration} Month/s</span>;
			},
		},
		{
			title: (
				<>
					<MilitaryTechIcon className="menu-icon" /> Renewed Date
				</>
			),
			render: (rowData) => {
				return <span>{rowData.renewed_date}</span>;
			},
		},
		{
			title: (
				<>
					<MilitaryTechIcon className="menu-icon" /> Expiration Date
				</>
			),
			render: (rowData) => {
				return <span>{rowData.expiration_date}</span>;
			},
		},
		{
			title: (
				<>
					<MilitaryTechIcon className="menu-icon" /> Membership Amount
				</>
			),
			render: (rowData) => {
				return <span>Rs.{rowData.membership_amount}</span>;
			},
		},
		{
			title: (
				<>
					<MilitaryTechIcon className="menu-icon" /> Membership Status
				</>
			),
			render: (rowData) => {
				return (
					<span>
						{rowData.membership_status === "active_due"
							? "payment pending"
							: rowData.membership_status}
					</span>
				);
			},
		},
		{
			title: (
				<>
					<SettingsSuggestIcon className="menu-icon" /> Action
				</>
			),
			render: (rowData) => {
				return (
					<div className="action-buttons-container">
						{currentDate < rowData.expiration_date ? (
							<>
								<button
									className="btn btn-outline-warning"
									onClick={() => {
										_editEvaMembership(rowData, "edit");
									}}
								>
									Edit
								</button>

								{rowData.membership_status === "active_due" && (
									<button
										className={`btn btn-outline-danger`}
										onClick={() => {
											_editEvaMembership(
												{
													...rowData,
													membership_status: "active",
												},
												"edit"
											);
										}}
									>
										Paid
									</button>
								)}
							</>
						) : rowData.membership_status === "expired" ? (
							<></>
						) : (
							<>
								<button
									className={`btn btn-outline-danger`}
									onClick={() => {
										_editEvaMembership(
											{
												...rowData,
												membership_status: "active_due",
											},
											"renew"
										);
									}}
								>
									Pending Renew
								</button>

								<button
									className={`btn btn-outline-success`}
									onClick={() =>
										_editEvaMembership(
											{
												...rowData,
												membership_status: "active",
											},
											"renew"
										)
									}
								>
									Renew
								</button>
							</>
						)}
					</div>
				);
			},
		},
	];

	// displaying form
	const evaMembershipForm = () => {
		return (
			<form
				key={2}
				style={{ flex: 1 }}
				onSubmit={handleMembershipsubmit(_evaMembershipFormSubmit)}
			>
				<div className="subscription-form-input-container">
					<Controller
						name="company_info_id"
						control={evaMembershipControl}
						defaultValue=""
						render={({ field }) => (
							<SelectBox
								id="outlined-size-small company_info_id"
								className="search"
								label="Company Name"
								field={field}
								size="small"
								options={companyInfos}
								required
								errormessage={
									evaMembershipErrors?.company_info_id
										?.message
								}
							/>
						)}
					/>
					<Controller
						name="membership_type"
						control={evaMembershipControl}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								required
								id="outlined-size-small membership_type"
								label="Membership Type"
								type="text"
								field={field}
								errormessage={
									evaMembershipErrors?.membership_type
										?.message
								}
							/>
						)}
					/>
					<Controller
						name="membership_duration"
						control={evaMembershipControl}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								required
								id="outlined-size-small membershipDuration"
								label="Membership Duration"
								type="number"
								field={field}
								errormessage={
									evaMembershipErrors?.membership_duration
										?.message
								}
							/>
						)}
					/>
					<Controller
						name="membership_amount"
						control={evaMembershipControl}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								required
								id="outlined-size-small membershipAmount"
								label="Membership Amount"
								type="number"
								field={field}
								errormessage={
									evaMembershipErrors?.membership_amount
										?.message
								}
							/>
						)}
					/>
					<Controller
						name="renewed_date"
						control={evaMembershipControl}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								required
								id="outlined-size-small renewedDate"
								label="Renewed Date"
								type="date"
								field={field}
								errormessage={
									evaMembershipErrors?.renewed_date?.message
								}
							/>
						)}
					/>
					<Controller
						name="expiration_date"
						control={evaMembershipControl}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								required
								id="outlined-size-small expirationDate"
								label="Expiration Date"
								type="date"
								field={field}
								errormessage={
									evaMembershipErrors?.expiration_date
										?.message
								}
							/>
						)}
					/>
					<div className="package-action-btn">
						<button
							className="btn btn-outline-secondary text-uppercase"
							type="submit"
						>
							{actionButton === "add"
								? "Add New Membership"
								: actionButton === "edit"
								? "Update Membership"
								: "Renew Membership"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	return (
		<div className="subscription-main-container">
			<div className="cater-subscription-form">{evaMembershipForm()}</div>
			<div className="cater-subscription-list">
				<Table
					cols={membershipListTable}
					data={memberships}
					isDark
					bordered
					striped
					hoverable
				/>
			</div>
		</div>
	);
}
