import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./user.style.scss";
import TtyIcon from "@mui/icons-material/Tty";
import MailIcon from "@mui/icons-material/Mail";
import CakeIcon from "@mui/icons-material/Cake";
import Person3Icon from "@mui/icons-material/Person3";
import Person4Icon from "@mui/icons-material/Person4";
import CancelIcon from "@mui/icons-material/Cancel";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";

import { getAllUsers } from "../../redux/actions/login-action";

export default function User() {
	const dispatch = useDispatch();
	const users = useSelector((state) => state.login.users);

	useEffect(() => {
		dispatch(getAllUsers());
	}, [dispatch]);

	return (
		<div className="user-container">
			<div className="user-container-inner">
				{users?.map((user, index) => (
					<div key={index} className="user-item-container">
						<div className="item-img-container">
							{user.user_image ? (
								<img src={user.user_image} alt="user" />
							) : user.user_gender ? (
								user.user_gender === "male" ? (
									<Person4Icon className="user-icon" />
								) : (
									<Person3Icon className="user-icon" />
								)
							) : (
								<SelfImprovementIcon className="user-icon" />
							)}
							<div
								className={`user-verified ${user.user_status}`}
							>
								{user.user_status === "active" ? (
									<CheckCircleIcon className="menu-icon" />
								) : (
									<CancelIcon className="menu-icon" />
								)}
							</div>
						</div>
						<div className="item-details-container">
							<div>
								<h4 className="item-mainText">
									{user?.user_id}. {user?.first_name}{" "}
									{user?.last_name}{" "}
									{user.createdAt ? (
										<OnlinePredictionIcon className="user-online active" />
									) : (
										<WifiOffIcon className="user-online offline" />
									)}
									{/* </div> */}
								</h4>
								<div className="details-divider" />
							</div>
							<div style={{ flex: 1 }}>
								<div className="item-info">
									<div className="info-icon a">
										<TtyIcon className="menu-icon" />
									</div>
									<div className="info-details">
										<p className="subText">Contact</p>
										<p className="mainText">
											{user?.primary_contact}
										</p>
									</div>
								</div>
								{user?.user_email && (
									<div className="item-info">
										<div className="info-icon b">
											<MailIcon className="menu-icon" />
										</div>
										<div className="info-details">
											<p className="subText">Email</p>
											<p className="mainText">
												{user?.user_email}
											</p>
										</div>
									</div>
								)}

								<div className="item-info">
									<div className="info-icon c">
										<CakeIcon className="menu-icon" />
									</div>
									<div className="info-details">
										<p className="subText">Date of Birth</p>
										<p className="mainText">
											{user.user_dob}
										</p>
									</div>
								</div>

								<div className="item-info">
									<div className="info-icon c">
										<LocationOnIcon className="menu-icon" />
									</div>
									<div className="info-details">
										<p className="subText">Location</p>
										<p className="mainText">
											{user.address}, {user.city}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
