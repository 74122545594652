import {
	// requests
	FETCH_MEMBER_REQUEST,
	ADD_MEMBER_REQUEST,
	UPDATE_MEMBER_REQUEST,
	// success
	FETCH_MEMBER_SUCCESS,
	ADD_MEMBER_SUCCESS,
	UPDATE_MEMBER_SUCCESS,
	FETCH_SINGLE_MEMBER_SUCCESS,
	// failure
	FETCH_MEMBER_FAILURE,
	ADD_MEMBER_FAILURE,
	UPDATE_MEMBER_FAILURE,
	// user
	FETCH_USER_LOGOUT,
} from "../action-types";

import axios from "axios";
import {
	GET_MEMBER_LIST,
	GET_MEMBER_BY_ID,
	SEARCH_MEMBER,
	ADD_MEMBER,
	ADD_COMPANY_MEMBER,
	UPDATE_MEMBER,
} from "../../constants/endpoints";

import setAuthToken from "../../helpers/setAuthToken";

import {
	isEmptyObject,
	sessionInfo,
	showToastMessage,
} from "../../helpers/functions";

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		// history.push({ pathname: "/" });dispatch();
		await dispatch({
			type: FETCH_USER_LOGOUT,
			error: error.response.data.message,
		});
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch({
			type: FETCH_MEMBER_FAILURE,
			error: error.response.data.message,
		});
	} else {
		showToastMessage(error.message, "E");
		await dispatch({
			type: FETCH_MEMBER_FAILURE,
			error: error.message,
		});
	}
}

export const getMemberList = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_MEMBER_REQUEST,
	});

	await axios
		.get(GET_MEMBER_LIST)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: FETCH_MEMBER_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: FETCH_MEMBER_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const getMemberById = (member_id) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_MEMBER_REQUEST,
	});

	await axios
		.get(GET_MEMBER_BY_ID + `?member_id=${member_id}`)

		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: FETCH_SINGLE_MEMBER_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: FETCH_MEMBER_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const searchMember = (searchData) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_MEMBER_REQUEST,
	});

	try {
		const response = await axios.get(
			SEARCH_MEMBER +
				`?member_id=${searchData.member_id}&first_name=${searchData.first_name}&last_name=${searchData.last_name}&primary_contact=${searchData.primary_contact}&member_status=${searchData.member_status}`
		);

		dispatch({
			type: FETCH_MEMBER_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_MEMBER_FAILURE,
			error: err.message,
		});
		_handleError(err, dispatch);
	}
};

export const addMember = (newMember) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: ADD_MEMBER_REQUEST,
	});

	await axios
		.post(ADD_MEMBER, newMember)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: ADD_MEMBER_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: ADD_MEMBER_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const addCompanyMember = (companyMember) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: ADD_MEMBER_REQUEST,
	});

	await axios
		.post(ADD_COMPANY_MEMBER, companyMember)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: ADD_MEMBER_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: ADD_MEMBER_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const updateMember = (memberInfo) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: UPDATE_MEMBER_REQUEST,
	});

	await axios
		.put(UPDATE_MEMBER, memberInfo)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: UPDATE_MEMBER_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: UPDATE_MEMBER_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};
