import {
	// requests
	ADD_MEMBERSHIP_REQUEST,
	FETCH_MEMBERSHIP_REQUEST,
	DELETE_MEMBERSHIP_REQUEST,
	UPDATE_MEMBERSHIP_REQUEST,
	FETCH_COMPANY_MEMBERSHIP_REQUEST,
	// success
	ADD_MEMBERSHIP_SUCCESS,
	FETCH_MEMBERSHIP_SUCCESS,
	DELETE_MEMBERSHIP_SUCCESS,
	UPDATE_MEMBERSHIP_SUCCESS,
	FETCH_COMPANY_MEMBERSHIP_SUCCESS,
	// failure
	FETCH_MEMBERSHIP_FAILURE,
	FETCH_COMPANY_MEMBERSHIP_FAILURE,
	ADD_MEMBERSHIP_FAILURE,
	UPDATE_MEMBERSHIP_FAILURE,
	DELETE_MEMBERSHIP_FAILURE,
	// USER
	FETCH_USER_LOGOUT,
} from "../action-types";

import axios from "axios";
import {
	GET_MEMBERSHIP_LIST,
	GET_MEMBERSHIP_BY_COMPANY,
	ADD_NEW_MEMBERSHIP,
	UPDATE_MEMBERSHIP,
	DELETE_MEMBERSHIP,
} from "../../constants/endpoints";

import setAuthToken from "../../helpers/setAuthToken";

import {
	isEmptyObject,
	sessionInfo,
	showToastMessage,
} from "../../helpers/functions";

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		// history.push({ pathname: "/" });dispatch();
		await dispatch({
			type: FETCH_USER_LOGOUT,
			error: error.response.data.message,
		});
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch({
			type: FETCH_MEMBERSHIP_FAILURE,
			error: error.response.data.message,
		});
	} else {
		showToastMessage(error.message, "E");
		await dispatch({
			type: FETCH_MEMBERSHIP_FAILURE,
			error: error.message,
		});
	}
}

export const getMembershipList = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_MEMBERSHIP_REQUEST,
	});

	await axios
		.get(GET_MEMBERSHIP_LIST)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: FETCH_MEMBERSHIP_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: FETCH_MEMBERSHIP_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const getMembershipByCompany = (company_info_id) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_COMPANY_MEMBERSHIP_REQUEST,
	});

	await axios
		.get(GET_MEMBERSHIP_BY_COMPANY + `?company_info_id=${company_info_id}`)

		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: FETCH_COMPANY_MEMBERSHIP_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: FETCH_COMPANY_MEMBERSHIP_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const addMembership = (newMembership) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: ADD_MEMBERSHIP_REQUEST,
	});

	await axios
		.post(ADD_NEW_MEMBERSHIP, newMembership)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: ADD_MEMBERSHIP_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: ADD_MEMBERSHIP_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const updateMembership = (membership) => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.evaLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: UPDATE_MEMBERSHIP_REQUEST,
	});

	await axios
		.put(UPDATE_MEMBERSHIP, membership)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: UPDATE_MEMBERSHIP_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: UPDATE_MEMBERSHIP_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};

export const deleteMembership =
	(membership_id, company_info_id) => async (dispatch) => {
		const sessionData = await sessionInfo();

		if (localStorage.evaLoggedIn) {
			setAuthToken(sessionData.token);
		}

		dispatch({
			type: DELETE_MEMBERSHIP_REQUEST,
		});

		await axios
			.delete(
				DELETE_MEMBERSHIP +
					`?membership_id=${membership_id}&company_info_id=${company_info_id}`
			)
			.then((response) => {
				if (response.data.success) {
					dispatch({
						type: DELETE_MEMBERSHIP_SUCCESS,
						payload: response.data.result,
					});
				} else {
					showToastMessage(response.data.message, "E");
					dispatch({
						type: DELETE_MEMBERSHIP_FAILURE,
						error: response.data.message,
					});
				}
			})
			.catch((err) => {
				_handleError(err, dispatch);
			});
	};
