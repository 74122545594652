import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import { userVerification } from "../../redux/actions/login-action";

export default function UserVerification(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const userVerificationStatus = useSelector(
		(state) => state.login.userVerificationStatus
	);

	useEffect(() => {
		const { token, email } = props.match.params;

		const verify = async () => {
			await dispatch(userVerification({ token, email }));
		};

		verify();
	}, [dispatch, props.match.params]);

	function errorView() {
		return (
			<div className="bg-default form-main-content">
				<div className="form-notice-title">
					User Verification Failed!
				</div>

				<div className="form-notice-message">Failed to verify user</div>
				<div className="form-notice-controller two">
					<Link to="/">
						<button className="button-primary">Sign In</button>
					</Link>
				</div>
			</div>
		);
	}

	function successView() {
		return (
			<div className="bg-default form-main-content">
				<div className="form-notice-title">Congratulations!</div>
				<div className="form-notice-sub-title">
					Welcome to EVA Nepal
				</div>

				<div className="form-notice-message">
					You have been invited to access EVA Nepal
				</div>

				<div className="form-notice-controller two">
					<button
						className="button-primary"
						onClick={() => history.push("/dashboard")}
					>
						Procceed
					</button>
				</div>
			</div>
		);
	}

	return (
		<div className="signin-page-wrapper">
			<div className="signup-main-container">
				<div className="company-title">EVA NEPAL</div>
				{userVerificationStatus ? successView() : errorView()}
			</div>
		</div>
	);
}
