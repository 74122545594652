import {
	// request
	FETCH_MEMBER_REQUEST,
	ADD_MEMBER_REQUEST,
	UPDATE_MEMBER_REQUEST,
	// failure
	FETCH_MEMBER_FAILURE,
	ADD_MEMBER_FAILURE,
	UPDATE_MEMBER_FAILURE,
	// success
	FETCH_MEMBER_SUCCESS,
	ADD_MEMBER_SUCCESS,
	UPDATE_MEMBER_SUCCESS,
	FETCH_SINGLE_MEMBER_SUCCESS,
} from "../action-types";

const initialState = {
	members: [],
	member: {},
	isLoading: false,
	error: "",
};

const caterPackageReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_MEMBER_REQUEST:
		case ADD_MEMBER_REQUEST:
		case UPDATE_MEMBER_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_MEMBER_SUCCESS:
		case ADD_MEMBER_SUCCESS:
		case UPDATE_MEMBER_SUCCESS:
			return {
				...state,
				members: payload,
				isLoading: false,
			};
		case FETCH_SINGLE_MEMBER_SUCCESS:
			return {
				...state,
				member: payload,
				isLoading: false,
			};
		case FETCH_MEMBER_FAILURE:
		case ADD_MEMBER_FAILURE:
		case UPDATE_MEMBER_FAILURE:
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default caterPackageReducer;
