import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./member.style.scss";
import TtyIcon from "@mui/icons-material/Tty";
import MailIcon from "@mui/icons-material/Mail";
import CakeIcon from "@mui/icons-material/Cake";
import Person3Icon from "@mui/icons-material/Person3";
import Person4Icon from "@mui/icons-material/Person4";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";

// import LocationCityIcon from "@mui/icons-material/LocationCity";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";

import { getMemberList } from "../../redux/actions/member-action";

export default function Member() {
	const dispatch = useDispatch();
	const memberList = useSelector((state) => state.member.members);

	useEffect(() => {
		dispatch(getMemberList());
	}, [dispatch]);

	return (
		<div className="customer-container">
			<div className="customer-container-inner">
				{memberList?.map((member, index) => (
					<div key={index} className="user-item-container">
						<div className="item-img-container">
							{member.member_image ? (
								<img src={member.member_image} alt="customer" />
							) : member.member_gender !== "other" ? (
								member.member_gender === "male" ? (
									<Person4Icon className="customer-icon" />
								) : (
									<Person3Icon className="customer-icon" />
								)
							) : (
								<SelfImprovementIcon className="customer-icon" />
							)}
							<div className="user-verified active">
								<CheckCircleIcon className="menu-icon" />
							</div>
						</div>
						<div className="item-details-container">
							<div>
								<h4 className="item-mainText">
									{member?.member_id}
									{". "}
									{member?.first_name} {member?.last_name}
								</h4>
								<div className="details-divider" />
							</div>
							<div style={{ flex: 1 }}>
								<div className="item-info">
									<div className="info-icon a">
										<LocationCityIcon className="menu-icon" />
									</div>
									<div className="info-details">
										<p className="subText">company</p>
										<p className="mainText">
											{member?.company_members[0]?.company
												?.company_name ||
												member?.company_members[0]
													?.outsideCompany
													?.company_name}
										</p>
									</div>
								</div>
								<div className="item-info">
									<div className="info-icon a">
										<TtyIcon className="menu-icon" />
									</div>
									<div className="info-details">
										<p className="subText">Contact</p>
										<p className="mainText">
											{member?.primary_contact}
										</p>
									</div>
								</div>
								{member?.member_email && (
									<div className="item-info">
										<div className="info-icon b">
											<MailIcon className="menu-icon" />
										</div>
										<div className="info-details">
											<p className="subText">Email</p>
											<p className="mainText">
												{member?.member_email}
											</p>
										</div>
									</div>
								)}

								<div className="item-info">
									<div className="info-icon c">
										<CakeIcon className="menu-icon" />
									</div>
									<div className="info-details">
										<p className="subText">Date of Birth</p>
										<p className="mainText">
											{member.member_dob}
										</p>
									</div>
								</div>

								<div className="item-info">
									<div className="info-icon c">
										<LocationOnIcon className="menu-icon" />
									</div>
									<div className="info-details">
										<p className="subText">Location</p>
										<p className="mainText">
											{member.address}, {member.city}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
