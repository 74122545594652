export const rolesOption = [
	{ id: "admin", value: "Admin" },
	{ id: "manager", value: "Manager" },
];

export const genderOption = [
	{ id: "male", value: "Male" },
	{ id: "female", value: "Female" },
	{ id: "other", value: "Other" },
];

export const companyTypeOption = [
	{ id: "advertisement", value: "Advertisement" },
	{ id: "IT", value: "IT" },
	{ id: "designing", value: "Interior Desiging" },
	{ id: "meat", value: "Meat Vendor" },
];

export const subscriptionStatusOption = [
	{ id: "active", value: "Active" },
	{ id: "expired", value: "Expired" },
	{ id: "active_due", value: "Payment Due Active" },
];

export const issuePage = [
	{ id: "booking", value: "Booking" },
	{ id: "dashboard", value: "Dashboard" },
	{ id: "settings", value: "Settings" },
];

export const supportType = [
	{ id: "crash", value: "Crash" },
	{ id: "error", value: "Error" },
	{ id: "feature request", value: "Feature Request" },
	{ id: "help", value: "Help" },
];
export const supportStatus = [
	{ id: "pending", value: "Pending" },
	{ id: "open", value: "Open" },
	{ id: "wip", value: "In Progress" },
	{ id: "closed", value: "Closed" },
];

export const countryOption = [
	{
		id: "nepal",
		value: "Nepal",
	},
];

export const cityOption = [
	{ id: "kathmandu", value: "Kathmandu" },
	{ id: "pokhara", value: "Pokhara" },
	{ id: "lalitpur", value: "Lalitpur" },
	{ id: "biratnagar", value: "Biratnagar" },
	{ id: "birgunj", value: "Birgunj" },
	{ id: "bharatpur", value: "Bharatpur" },
	{ id: "janakpur", value: "Janakpur" },
	{ id: "hetauda", value: "Hetauda" },
	{ id: "dharan", value: "Dharan" },
	{ id: "butwal", value: "Butwal" },
	{ id: "bhaktapur", value: "Bhaktapur" },
	{ id: "birendranagar", value: "Birendranagar" },
	{ id: "itahari", value: "Itahari" },
	{ id: "nepalganj", value: "Nepalganj" },
	{ id: "ghorahi", value: "Ghorahi" },
	{ id: "siddharthanagar", value: "Siddharthanagar" },
	{ id: "rajbiraj", value: "Rajbiraj" },
	{ id: "dhangadhi", value: "Dhangadhi" },
	{ id: "mahendranagar", value: "Mahendranagar" },
	{ id: "gulariya", value: "Gulariya" },
	{ id: "tulsipur", value: "Tulsipur" },
	{ id: "narayangarh", value: "Narayangarh" },
	{ id: "ithari", value: "Ithari" },
	{ id: "baglung", value: "Baglung" },
	{ id: "banepa", value: "Banepa" },
];
