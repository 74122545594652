import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Route } from "react-router-dom";
// import history from "../helpers/history";

import SideNavigation from "../components/sidebar/Sidebar";

// css and icons
import companyLogo from "../assets/logo/evanepal.png";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import "../assets/scss/main.scss";

// actions
import { logoutUser } from "../redux/actions/login-action";
import { getAllReminders } from "../redux/actions/reminder-action";

export default function ProtectedRoute(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	const unseenReminder = useSelector(
		(state) => state.reminder.unseenReminder
	);

	let navigation = true;
	let evaLoggedIn = localStorage.getItem("evaLoggedIn");

	if (props.navigation === false) {
		navigation = false;
	}

	useEffect(() => {
		dispatch(getAllReminders());
	}, [dispatch]);

	const toggleSideNav = () => {
		let sidnav = document.getElementById("sidenav");

		if (sidnav.style.display === "block") {
			sidnav.style.display = "none";
		} else {
			sidnav.style.display = "block";
		}
	};

	const logout = async () => {
		await dispatch(logoutUser());
		history.push({ pathname: "/" });
	};

	return (
		<Route
			path={props.path}
			render={(data) =>
				evaLoggedIn ? (
					navigation === true ? (
						<div className="main-wrapper">
							<div className="top-nav-container">
								<span className="toggle-icon" id="toggle-icon">
									<img
										src={companyLogo}
										alt="EVA NEPAL"
										onClick={toggleSideNav}
									/>
									<label className="company-title">
										EVA NEPAL
									</label>
								</span>
								<div className="user-settings">
									<span className="user-icon-nav">
										{unseenReminder}
										<NotificationsActiveIcon />
									</span>
									<span className="user-icon-nav">
										<PersonIcon />
									</span>
									<span className="user-dropdown">
										<ul>
											{/* <li>Settings</li> */}
											<li onClick={logout}>
												<Link to="/">
													<div className="menu-container">
														<LogoutIcon />
														<span className="menu-label">
															{" "}
															Logout
														</span>
													</div>
												</Link>
											</li>
										</ul>
									</span>
								</div>
							</div>

							<div className="main-container">
								{/* sideNavOverLay() */}
								<div className="side-nav-content" id="sidenav">
									<SideNavigation />
								</div>
								<props.component {...data}></props.component>
							</div>
						</div>
					) : (
						<props.component {...data}></props.component>
					)
				) : (
					<Redirect to="/" />
				)
			}
		></Route>
	);
}
