let API_URL, NEW_API_URL;

const APP = process.env.REACT_APP_ENV;

if (APP === "production") {
	// production environment
	API_URL = "https://api-caterme.nibjar.com/reservation";
	NEW_API_URL = "https://api-eva-nepal.nibjar.com";
} else if (APP === "testing") {
	// testing environment
	API_URL = "https://test-api-caterme.nibjar.com/reservation";
	NEW_API_URL = "https://test-api-eva-nepal.nibjar.com";
} else if (APP === "development") {
	// development environment
	API_URL = "http://localhost:3000/reservation";
	NEW_API_URL = "http://localhost:3004";
}
export { API_URL, NEW_API_URL };
