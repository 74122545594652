import React from "react";
import "./Sidebar.scss";

// icons from material UI
import GroupIcon from "@mui/icons-material/Group";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
// import BackupTableIcon from "@mui/icons-material/BackupTable";
// import SupportAgentIcon from "@mui/icons-material/SupportAgent";
// import SubscriptionsIcon from "@mui/icons-material/Subscriptions";

import { Link } from "react-router-dom";

const SideNavigation = () => {
	// const [toggle, setToggle] = useState(true);
	// function toggleSidebar() {
	// 	let sideNav = document.getElementById("sideNavWrapper");
	// 	if (toggle) {
	// 		sideNav.style.width = "250px";
	// 		setToggle(false);
	// 	} else {
	// 		sideNav.style.width = "70px";
	// 		setToggle(true);
	// 	}
	// }

	return (
		<div className="side-navigation-wrapper" id="sideNavWrapper">
			<div className="side-menu-container">
				<Link to="/dashboard">
					<div className="menu-container">
						<DashboardIcon className="menu-icon" />
						<span className="menu-label">Dashboard</span>
					</div>
				</Link>
				<Link to="/add/user">
					<div className="menu-container">
						<AddBusinessIcon className="menu-icon" />
						<span className="menu-label">Add User</span>
					</div>
				</Link>
				<Link to="/users">
					<div className="menu-container">
						<GroupIcon className="menu-icon" />
						<span className="menu-label">System Users</span>
					</div>
				</Link>
				<Link to="/add/company">
					<div className="menu-container">
						<AddBusinessIcon className="menu-icon" />
						<span className="menu-label">Add Company</span>
					</div>
				</Link>
				<Link to="/company">
					<div className="menu-container">
						<LocationCityIcon className="menu-icon" />
						<span className="menu-label">Company</span>
					</div>
				</Link>
				<Link to="/add/outsideCompany">
					<div className="menu-container">
						<AddBusinessIcon className="menu-icon" />
						<span className="menu-label">Add Outside Company</span>
					</div>
				</Link>
				<Link to="/outsideCompany">
					<div className="menu-container">
						<LocationCityIcon className="menu-icon" />
						<span className="menu-label">Outside Company</span>
					</div>
				</Link>
				<Link to="/add/member">
					<div className="menu-container">
						<AddBusinessIcon className="menu-icon" />
						<span className="menu-label">Add Member</span>
					</div>
				</Link>
				<Link to="/members">
					<div className="menu-container">
						<Diversity3Icon className="menu-icon" />
						<span className="menu-label">Members</span>
					</div>
				</Link>
				<Link to="/membership">
					<div className="menu-container">
						<CardMembershipIcon className="menu-icon" />
						<span className="menu-label">Membership</span>
					</div>
				</Link>
				<Link to="/add/reminder">
					<div className="menu-container">
						<DashboardIcon className="menu-icon" />
						<span className="menu-label">Add Reminder</span>
					</div>
				</Link>
				<Link to="/reminders">
					<div className="menu-container">
						<DashboardIcon className="menu-icon" />
						<span className="menu-label">Reminders</span>
					</div>
				</Link>
				<Link to="/add/event">
					<div className="menu-container">
						<DashboardIcon className="menu-icon" />
						<span className="menu-label">Add Event</span>
					</div>
				</Link>
				<Link to="/events">
					<div className="menu-container">
						<DashboardIcon className="menu-icon" />
						<span className="menu-label">Events</span>
					</div>
				</Link>
				<Link to="/whatsapp">
					<div className="menu-container">
						<CardMembershipIcon className="menu-icon" />
						<span className="menu-label">Whatsapp</span>
					</div>
				</Link>
			</div>
		</div>
	);
};
export default SideNavigation;
