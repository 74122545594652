import React, { useState } from "react";
import "./text-input-error.style.scss";

import { FormControl } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import { InputAdornment } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";

const PasswordInput = (props) => {
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	return (
		<div className="input-container">
			<FormControl variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">
					{props.label}
				</InputLabel>
				<OutlinedInput
					required
					id="outlined-adornment-password"
					type={showPassword ? "text" : "password"}
					{...props}
					{...props.field}
					error={props.errormessage ? true : false}
					label="Password"
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? (
									<Visibility />
								) : (
									<VisibilityOff />
								)}
							</IconButton>
						</InputAdornment>
					}
				/>
			</FormControl>
			<span className="error-message">{props.errormessage}</span>
		</div>
	);
};
export default PasswordInput;
