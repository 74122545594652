import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import "../Company/addCompany.style.scss";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components imported
import { genderOption, cityOption } from "../../constants/variables";
import SelectBox from "../../components/select-box/select-box.input";
import TextInput from "../../components/text-input/text-input-error.component";

// actions
import { addMember } from "../../redux/actions/member-action";
import {
	getAllCompanies,
	getAllOutsideCompanies,
} from "../../redux/actions/company-action";

const memberSchema = yup.object().shape({
	company_info_id: yup.string().required("company is required"),
	first_name: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	last_name: yup
		.string()
		.required("required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	primary_contact: yup
		.number()
		.typeError("Must be a number")
		.required("required"),
	member_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
	member_dob: yup.string().required("required"),
	member_gender: yup.string().required("required"),
	address: yup.string().required("required"),
	city: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
});

export default function AddMember() {
	const history = useHistory();
	const dispatch = useDispatch();
	// displaying control state
	const companies = useSelector((state) => state.company.companies);
	const outsideCompanies = useSelector(
		(state) => state.company.outsideCompanies
	);
	const [memberFormState, setMemberFormState] = useState(true);
	const [showPreview, setShowPreview] = useState(false);

	// form submission state
	const [memberData, setMemberState] = useState({});

	// for select box
	let companyInfos = companies.map((company) => {
		return {
			id: company.company_info_id,
			value: company.company_name,
		};
	});
	outsideCompanies.forEach((company) => {
		companyInfos.push({
			id: company.outside_company_id,
			value: company.company_name,
		});
	});

	// member form control
	const {
		control: memberControl,
		handleSubmit: handleMemberSubmit,
		formState: { errors: memberErrors },
	} = useForm({
		defaultValues: {},
		mode: "onBlur",
		resolver: yupResolver(memberSchema),
	});

	useEffect(() => {
		dispatch(getAllCompanies());
		dispatch(getAllOutsideCompanies());
	}, [dispatch]);

	// handling view
	const _memberTab = () => {
		setMemberFormState(true);
		setShowPreview(false);
	};

	const _previewTab = () => {
		setMemberFormState(false);
		setShowPreview(true);
	};

	// handling form
	const _memberFormSubmit = (data) => {
		setMemberState({ ...memberData, ...data });
		setMemberFormState(false);
		setShowPreview(true);
	};

	const _addMember = () => {
		dispatch(addMember(memberData));
		history.push({
			pathname: "/members",
		});
	};

	// displaying form
	const memberForm = () => {
		return (
			<form
				key={3}
				style={{ flex: 1 }}
				onSubmit={handleMemberSubmit(_memberFormSubmit)}
			>
				<div className="form-main-content bg-default">
					<div className="form-group">
						<div className="form-input-container">
							<Controller
								name="company_info_id"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small company_info_id"
										className="search"
										label="Company Name"
										field={field}
										size="small"
										options={companyInfos}
										required
										errormessage={
											memberErrors?.company_info_id
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="first_name"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small firstName"
										label="First Name"
										type="text"
										field={field}
										errormessage={
											memberErrors?.first_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="last_name"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small lastName"
										label="Last Name"
										type="text"
										field={field}
										errormessage={
											memberErrors?.last_name?.message
										}
									/>
								)}
							/>
							<Controller
								name="primary_contact"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userMobile"
										label="Primary Contact"
										type="number"
										field={field}
										errormessage={
											memberErrors?.primary_contact
												?.message
										}
									/>
								)}
							/>
							<Controller
								name="member_email"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userEmail"
										label="Member Email"
										type="email"
										field={field}
										errormessage={
											memberErrors?.member_email?.message
										}
									/>
								)}
							/>
						</div>
						<div className="form-input-container">
							<Controller
								name="member_dob"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userDOB"
										// label="Date of Birth"
										type="date"
										field={field}
										errormessage={
											memberErrors?.member_dob?.message
										}
									/>
								)}
							/>
							<Controller
								name="member_gender"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small package_type"
										className="search"
										label="Gender"
										field={field}
										size="small"
										options={genderOption}
										required
										errormessage={
											memberErrors?.member_gender?.message
												?.member_gender?.message
										}
									/>
								)}
							/>
							<Controller
								name="address"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<TextInput
										required
										id="outlined-size-small userAddress"
										label="Address"
										type="text"
										field={field}
										errormessage={
											memberErrors?.address?.message
										}
									/>
								)}
							/>
							<Controller
								name="city"
								control={memberControl}
								defaultValue=""
								render={({ field }) => (
									<SelectBox
										id="outlined-size-small userCity"
										className="search"
										label="City"
										field={field}
										size="small"
										options={cityOption}
										required
										errormessage={
											memberErrors?.city?.message
										}
									/>
								)}
							/>
						</div>
					</div>
					<div className="user-action-btn">
						<button
							className="btn btn-outline-secondary"
							type="submit"
						>
							Next {">"}
						</button>
					</div>
				</div>
			</form>
		);
	};

	// displaying data
	const memberInfo = useMemo(() => {
		return [
			{
				label: "First Name",
				value: memberData.first_name,
			},
			{
				label: "Last Name",
				value: memberData.last_name,
			},
			{
				label: "Gender",
				value: memberData.member_gender,
			},
			{
				label: "Date of Birth",
				value: memberData.member_dob || "-",
			},
			{
				label: "Primary Contact",
				value: memberData.primary_contact,
			},
			{
				label: "Email",
				value: memberData.member_email,
			},
			{
				label: "Address",
				value: memberData.address,
			},
			{
				label: "City",
				value: memberData.city,
			},
		];
	}, [memberData]);

	return (
		<>
			{
				<div className="signup-main">
					<div className="signup-phase">
						<div
							className="user-form"
							onClick={() => {
								_memberTab();
							}}
						>
							Member Information
						</div>

						<div
							className="preview-form"
							onClick={() => {
								_previewTab();
							}}
						>
							Preview
						</div>
					</div>

					{memberFormState && memberForm()}

					{showPreview && (
						<div className="preview-data-main">
							<div className="preview-user">
								<div className="section-header">
									<h2>Member Information</h2>
									<div
										className="form-edit-button"
										onClick={() => _memberTab()}
									>
										Edit
									</div>
								</div>
								<div className="section-body">
									{memberInfo.map((info, index) => (
										<div key={index} className="form-data">
											<div className="data-label">
												{info.label}
											</div>
											<div className="data-value">
												{info.value}
											</div>
										</div>
									))}
								</div>
							</div>
							<div>
								<button
									className="btn btn-outline-secondary text-uppercase"
									onClick={() => _addMember()}
								>
									Add New Member
								</button>
							</div>
						</div>
					)}
				</div>
			}
		</>
	);
}
